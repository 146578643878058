import { useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import 'dayjs/locale/ru';
import Button from "@mui/material/Button";
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import { FormProvider, useForm } from "react-hook-form";
import FormsAccordion from "../../../components/Forms/formsArrangement/FormsAccordion";
import MenuItem from "@mui/material/MenuItem";
import useApi from "../../../customHooks/useApi";
import { isDevMode, parseErrorsToArray, preparePatientData } from "../../../utils/helperFunctions";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import StickyPageHeader from "../../../components/Layout/UI/InterfaceElements/StickyPageHeader";
import pageInfo from "./pageInfo";
import { useSnackbar } from "notistack";
import { defaultPatientCreationValues, examplePatientCreationValues } from "../../../utils/defaultFormsValues";
import { patientsUri } from "../../../utils/uris";

import ContactDataForm from "../../../components/Forms/ContactDataForm"
import PersonalDataForm from "../../../components/Forms/PersonalDataForm"
import PassportDataForm from "../../../components/Forms/PassportDataForm"
import AdditionalDataForm from "../../../components/Forms/AdditionalDataForm"
import PatientCreationConfirmationDialog from "./PatientCreationConfirmationDialog"

const formsArray = [
    {
        id: "contactData",
        title: "Контактные данные",
        component: ContactDataForm,
    },
    {
        id: "personalData",
        title: "Персональные данные",
        component: PersonalDataForm,
        props: {
            fieldsMap: {
                name: "patient.name",
                surname: "patient.surname",
                patronymic: "patient.patronymic",
                gender: "patient.gender",
                birth_date: "patient.birth_date"
            }
        }
    },
    {
        id: "passportData",
        title: "Паспортные данные",
        component: PassportDataForm,
    },
    {
        id: "additionalData",
        title: "Дополнительные данные",
        component: AdditionalDataForm,
        fieldsMap: {
            snils: "patient.snils"
        }
    }
]


const PatientCreationForm = () => {
    const navigate = useNavigate();
    const { spacing } = useTheme();
    const [open, setOpen] = useState( false );
    const { makeRequest, isSubmitting } = useApi( `${ patientsUri }` );

    // React Hook Form
    const methods = useForm( {
        defaultValues: defaultPatientCreationValues,
        mode: "onBlur",
        reValidateMode: "onChange",
        shouldFocusError: false
    } );
    const { reset, formState, trigger, getValues, handleSubmit, setError, clearErrors } = methods;

    // Snackbar hook
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();


    const errorsHandler = (errorsObj) => {
        console.log( "Client side form errors", errorsObj );
        setOpen( false )
    };

    const submitHandler = async (data) => {
        const preparedData = preparePatientData( data );
        try {
            // post data
            const resData = await makeRequest( preparedData );
            // display notifications

            console.log( resData )

            enqueueSnackbar( "Пациент успешно создан", { variant: "success" } );
            enqueueSnackbar( "Открывается страница пациента...", { key: "patientPage" } );
            // navigate to new patient page after successful creation
            navigate( "/patients/" + resData?.patient?.id );
            closeSnackbar( "patientPage" );
        } catch (error) {
            const { response, body } = error

            enqueueSnackbar( `Ошибка ${ response.status }. ${ response.statusText }`, { variant: "error" } )

            console.log( "Server side form errors", body );
            if (body) {
                parseErrorsToArray( body ).forEach( (el) => {
                    let [[key, value],] = Object.entries( el );
                    setError( key, { type: "custom", message: value } );
                } )
            }
            console.log( "Server side form errors translated to client", formState.errors );
            setOpen( false );
        }
    };

    const actionButton = useMemo( () => {
        if (formState.isValid) {
            return (
                <Button variant='contained'
                        onClick={ () => {
                            setOpen( true )
                        } }
                        endIcon={ <ArrowForwardRoundedIcon/> }>
                    Добавить
                </Button>
            )
        }
        return (
            <Button onClick={ async () => await trigger() }>
                Проверить данные
            </Button>
        )
    }, [formState.isValid, trigger] );

    const menuItems = useMemo( () => {
        return [
            ...isDevMode ? [<MenuItem onClick={ () => {
                reset( examplePatientCreationValues )
            } }>
                Подставить тестовые значения
            </MenuItem>
            ] : [],
            <MenuItem onClick={ () => {
                reset( defaultPatientCreationValues );
                clearErrors()
            } }>
                Очистить форму
            </MenuItem>,
            <MenuItem onClick={ () => {
                setOpen( true )
            } }>
                Предпросмотр
            </MenuItem>,
        ]
    }, [reset, clearErrors] );

    return (
        <>
            <FormProvider { ...methods }>
                <form noValidate
                      autoComplete="off"
                      onSubmit={ event => event.preventDefault() }>

                    <StickyPageHeader title="Добавить пациента"
                                      button={ actionButton }
                                      menuItems={ menuItems }
                                      infoContent={ pageInfo() }/>

                    <Box paddingBottom={ spacing( 4 ) }>
                        <FormsAccordion formsArray={ formsArray }/>
                    </Box>
                </form>
            </FormProvider>
            <PatientCreationConfirmationDialog open={ open }
                                               onSubmit={ handleSubmit( submitHandler, errorsHandler ) }
                                               onCancel={ () => setOpen( false ) }
                                               onClose={ () => setOpen( false ) }
                                               data={ preparePatientData( getValues() ) }
                                               isLoading={ isSubmitting }/>
        </>
    );
};

export default PatientCreationForm;