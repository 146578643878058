import React, { useState } from 'react';
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import DropdownMenuButton from "./DropdownMenu/DropdownMenuButton";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AppBar from "@mui/material/AppBar";
import IconButton from '@mui/material/IconButton';
import HelpIcon from '@mui/icons-material/Help';
import { Tooltip } from "@mui/material";
import PageInfoDialog from "./Dialogs/PageInfoDialog";

const StickyPageHeader = ({ title, button, menuItems, infoContent }) => {
    const [infoDialogOpen, setInfoDialogOpen] = useState( false );

    const handleClickOpen = () => {
        setInfoDialogOpen( true );
    };
    const handleClose = () => {
        setInfoDialogOpen( false );
    };

    return (
        <AppBar position="sticky"
                color="grey"
                elevation={4}
                sx={ {
                    marginBottom: 4,
                    // marginTop: 2
                } }>
            <Toolbar sx={ { justifyContent: "space-between" } }>


                <Typography variant="h6">
                    { title }
                    {
                        infoContent &&
                        <>
                            <Tooltip title="Помощь">
                                <IconButton
                                    onClick={ handleClickOpen }
                                    aria-label="info"
                                    color="primary">
                                    <HelpIcon/>
                                </IconButton>
                            </Tooltip>
                            <PageInfoDialog
                                open={ infoDialogOpen }
                                handleClose={ handleClose }
                                content={ infoContent }/>
                        </>


                    }
                </Typography>


                <ButtonGroup>
                    { !!button && button }

                    { !!menuItems && <DropdownMenuButton
                        menuItems={ menuItems }
                        renderButton={ (params) => <Button
                            { ...params }
                            size="small"
                            variant='outlined'
                            aria-label="dropdown menu"
                            aria-haspopup="menu">
                            <ArrowDropDownIcon/>
                        </Button> }/> }

                </ButtonGroup>

            </Toolbar>

        </AppBar>
    );
};

export default StickyPageHeader;