import React from 'react';
import StickyPageHeader from "../../components/Layout/UI/InterfaceElements/StickyPageHeader";
import NotRealizedPage from "../../components/NotRealizedPage";

const Appointment = () => {
    return (
        <>
            <StickyPageHeader title="Запись к врачам"/>
            <NotRealizedPage/>
        </>
    );
};

export default Appointment;