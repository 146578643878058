import React from 'react';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const PageInfo = () => {
    return (
        <div>
            <p><CheckCircleIcon color="success"/> В текущей версии поддерживаются следующие возможности создания
                Пациента:
            </p>
            <ol>
                <li>
                    Контактные данные: обязательный основной мобильный телефон для связи
                </li>
                <li>
                    Персональные данные: ФИО и пол (поддерживается быстрый ввод), дата рождения.
                </li>
                <li>
                    Паспортные данные с быстрым вводом поля "Кем выдан".
                </li>
            </ol>
            <p><WarningRoundedIcon color="warning"/> Ограничения:</p>
            <ol>
                <li>Сохраняем в базу только лиц старше 15 лет (возраст согласия). Возможность сохранять детей с
                    указанием родителей
                    появится позже.
                </li>
                <li>Для каждого лица должен быть указан уникальный мобильный номер телефона. Возможность создания
                    семейных аккаунтов (несколько лиц на один номер) появится позже при необходимости.
                </li>
            </ol>
        </div>
    );
};

export default PageInfo;