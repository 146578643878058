import React from 'react';
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import ControlledTextField from "./FormFields/ControlledTextField";
import { RussianSnilsMask } from "./FormFields/textMasks";

const AdditionalDataForm = ({
                                fieldsMap = {
                                    snils: "patient.snils"
                                }
                            }) => {
    const { spacing } = useTheme();
    return (
        <Grid container flex flexDirection="column" gap={ spacing( 2 ) }>
            <Grid item xs={ 12 } md={ 4 }>
                <ControlledTextField
                    fieldName={ fieldsMap.snils }
                    label="СНИЛС"
                    placeholder="000-000-000 00"
                    fullWidth
                    mask={ RussianSnilsMask }
                />
            </Grid>
            <Grid item xs={ 12 }>
            </Grid>
        </Grid>
    );
};

export default AdditionalDataForm;