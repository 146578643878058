import React, { memo, useCallback } from 'react';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { calcAge, isDevMode, renderJsonView } from "../../../utils/helperFunctions";
import CardRow from "../../../components/Cards/CardElements/CardRow";
import CardBase from "../../../components/Cards/CardElements/CardBase";
import dayjs from "dayjs";
import Divider from "@mui/material/Divider";
import { dateFormat } from "../../../utils/defaults";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";

const renderRows = (rows) => {
    return rows.map( (row, i) => {
        return <CardRow title={ row.title } dense key={ i } spacing={ 2 }>
            { row.value || "Не указано" }
        </CardRow>
    } )
}

const PatientCreationConfirmationDialog = memo( ({ open, onClose, onCancel, onSubmit, data }) => {
        console.log( "PatientCreationConfirmationDialog render" )

        const renderResume = useCallback( (patientData) => {
            const {
                patient,
                passport,
                primary_phone_number,
                citizenship,
                living_address,
                registration_address
            } = patientData;
            const fullName = `${ patient.surname } ${ patient.name } ${ patient.patronymic }`;
            const formattedNumberPart = `${ primary_phone_number.number.slice( 0, 3 ) }-${ primary_phone_number.number.slice( 3, 5 ) }-${ primary_phone_number.number.slice( 5 ) }`;
            const fullNumber = `+${ primary_phone_number.country_code } (${ primary_phone_number.area_code }) ${ formattedNumberPart }`;
            const gender = !!patient.gender && (patient.gender === "m" ? "Муж." : "Жен.");
            const seriesNum = `${ passport.series } ${ passport.num }`;

            let rows;
            rows = [
                [
                    {
                        title: "Номер телефона",
                        value: fullNumber.replaceAll( /[+()\-\s]/g, "" ).length > 10 && fullNumber
                    }
                ],
                [
                    {
                        title: "ФИО",
                        value: !!fullName.replaceAll( " ", "" ) && fullName
                    },
                    {
                        title: "Пол",
                        value: gender
                    },
                    {
                        title: "Дата рождения",
                        value: !!patient?.birth_date && dayjs( patient.birth_date ).format( dateFormat )
                    },
                    {
                        title: "Полных лет",
                        value: !!patient?.birth_date && calcAge( patient.birth_date )
                    }
                ],
                [
                    {
                        title: "Гражданство",
                        value: !!citizenship?.country && (citizenship.country === "rus" ? "РФ" : citizenship.country.toUpperCase())
                    },
                    {
                        title: "Серия, номер паспорта",
                        value: !!seriesNum.replaceAll( " ", "" ) && seriesNum
                    },
                    {
                        title: "Выдан",
                        value: !!passport.issued_by && passport.issued_by
                    },
                    {
                        title: "Код подразделения",
                        value: !!passport.issuer_code && passport.issuer_code
                    },
                    {
                        title: "Дата выдачи",
                        value: !!passport?.from_date && dayjs( passport.from_date ).format( dateFormat )
                    }
                ],
                [
                    {
                        title: "Адрес регистрации",
                        value: !!registration_address?.value && registration_address.value
                    },
                    {
                        title: "Адрес проживания",
                        value: !!living_address?.value && living_address.value
                    }
                ],
                [
                    {
                        title: "СНИЛС",
                        value: !!patient?.snils && patient.snils
                    }
                ]
            ];

            return rows.map( (rowsSet, i) => {
                return <div key={ i }>
                    { renderRows( rowsSet ) }
                    <Divider sx={ { marginBottom: 2 } }/>
                </div>
            } )
        }, [] )

        return (
            <Dialog open={ open }
                    onClose={ onClose }
                    fullWidth
                    maxWidth="sm">
                <DialogTitle>Проверьте данные</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                    </DialogContentText>
                    <CardBase disableElevation sx={ { pt: 2 } }>
                        {
                            renderResume( data )
                        }

                    </CardBase>

                    {
                        isDevMode && <Accordion>
                            <AccordionSummary
                                expandIcon={ <ExpandMoreIcon/> }>
                                <Typography>Системный вид запроса</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                { renderJsonView( data ) }
                            </AccordionDetails>
                        </Accordion>
                    }

                </DialogContent>

                <DialogActions>
                    <Button onClick={ onCancel }>
                        Отмена
                    </Button>
                    <Button onClick={ onSubmit }>
                        Сохранить
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
)

export default PatientCreationConfirmationDialog;