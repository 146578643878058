import React, { useContext } from 'react';
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Avatar from '@mui/material/Avatar';
import { deepOrange } from '@mui/material/colors';
import { Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import LinearProgress from '@mui/material/LinearProgress';
import LoadingContext from "../../../../../store/loading-context";
import AuthContext from "../../../../../store/auth-context";
import MenuItem from "@mui/material/MenuItem";
import { Link, useNavigate } from "react-router-dom";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import DropdownMenuButton from "../DropdownMenu/DropdownMenuButton";
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';


const TopBar = ({ title, drawerToggleHandler, isDrawerOpen }) => {

    const { isLoading } = useContext( LoadingContext );
    const { logout } = useContext( AuthContext );
    const navigate = useNavigate();

    const logoutHandler = () => {
        logout();
        navigate( '/login' );
    };

    const accountMenuItems = [
        <MenuItem component={ Link } to="/account">
            <Avatar/> Мой аккаунт
        </MenuItem>,
        <Divider/>,
        <MenuItem component={ Link } to="/settings">
            <ListItemIcon>
                <Settings fontSize="small"/>
            </ListItemIcon>
            Настройки
        </MenuItem>,
        <MenuItem onClick={ logoutHandler }>
            <ListItemIcon>
                <Logout fontSize="small"/>
            </ListItemIcon>
            Выход
        </MenuItem>
    ]


    return (
        <>
            <AppBar position="fixed"
                    elevation={ 1 }
                    sx={ {
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    } }>

                { isLoading && <LinearProgress sx={ {
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                } }/> }

                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={ drawerToggleHandler }
                        edge="start"
                        sx={ {
                            marginRight: 5,
                        } }
                    >
                        { isDrawerOpen ? <MenuOpenIcon/> : <MenuIcon/> }
                    </IconButton>
                    <Typography
                        variant="h6"
                        noWrap>
                        { title }
                    </Typography>
                    <Box sx={ { flexGrow: 1 } }/>

                    <Box>
                        <Tooltip title="Уведомления">
                            <IconButton size="large">
                                <NotificationsIcon/>
                            </IconButton>
                        </Tooltip>

                        <DropdownMenuButton menuItems={ accountMenuItems }
                                            renderButton={ (params) => (
                                                <IconButton size="large" { ...params }>
                                                    <Tooltip title="Параметры аккаунта">
                                                        <Avatar
                                                            sx={ { bgcolor: deepOrange[500] } }
                                                            src="/broken-image.jpg"/>
                                                    </Tooltip>
                                                </IconButton>) }
                        />

                    </Box>
                </Toolbar>
            </AppBar>

        </>
    );
};

export default TopBar;
