import React from 'react';
import Grid from "@mui/material/Grid";
import { useFormContext } from "react-hook-form";
import { defaultCountry, requiredRule } from "./defaults/formsDefaults";
import AutocompleteField from "./FormFields/AutocompleteField";
import useData from "../../customHooks/useData";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import ControlledTextField from "./FormFields/ControlledTextField";
import { PhoneNumberMask } from "./FormFields/textMasks";
import useFieldApiValidation from "../../customHooks/useFieldApiValidation";
import { countriesPhoneCodesUri, validateUniquePhoneNumberUri } from "../../utils/uris";

const countryListItemRender = (props, option) => {
    let countryName = option.name
    if (countryName.length > 25) {
        countryName = countryName.substring( 0, 24 ) + '…'
    }
    const key = `+${ option.phone_code } ${ countryName }`;
    const flagUrl = `https://flagsapi.com/${ option.alpha2.toUpperCase() }/flat/24.png`;
    return <Box component="li"
                { ...props }
                sx={ { '& > img': { ml: 1, flexShrink: 0 } } }
                key={ key }>
        { key }
        <img src={ flagUrl } alt={ option.alpha3 } loading="lazy"/>
    </Box>
}
const isCountryEqualToValue = (option, value) => {
    return option.phone_code === value.phone_code && option.name === value.name
}
const getCountryLabel = (option) => `+${ option.phone_code }`


const ContactDataForm = () => {

    console.log( "ContactDataForm render" )

    const { data: countriesPhoneCodes } = useData( `${ countriesPhoneCodesUri }` );
    const { validateField, isLoading } = useFieldApiValidation( `${ validateUniquePhoneNumberUri }` )

    const { spacing } = useTheme();
    const { setValue } = useFormContext();


    return (
        <>
            <Grid container flex flexDirection="row" gap={ spacing( 2 ) }>
                <Grid item xs={ 2 }>
                    <AutocompleteField
                        changeHandler={ (value) => {
                            if (value.phone_code) {
                                setValue(
                                    "primary_phone_number.country_code",
                                    value.phone_code
                                )
                            }
                        } }
                        options={ countriesPhoneCodes }
                        fieldLabel="Код страны"
                        fullWidth
                        isOptionEqualToValue={ isCountryEqualToValue }
                        slotProps={ {
                            paper: {
                                sx: { width: "327px" }
                            }
                        } }
                        getOptionLabel={ getCountryLabel }
                        renderOption={ countryListItemRender }
                        preSelectedOption={ defaultCountry }/>

                </Grid>
                <Grid item xs={ 5 }>
                    <ControlledTextField
                        placeholder="(000) 000-00-00"
                        fieldName="primary_phone_number.number"
                        label="Номер телефона"
                        isLoading={ isLoading }
                        rules={ {
                            ...requiredRule,
                            validate: (value) => validateField( value ),
                            minLength: {
                                value: 10,
                                message: "Введите корректный номер"
                            }
                        } }
                        autoComplete="new-password"
                        fullWidth
                        mask={ PhoneNumberMask }
                        // autoFocus
                    />
                </Grid>
            </Grid>
            {/*<Grid container flex flexDirection="row" gap={ spacing( 2 ) }>*/ }
            {/*    <Grid item xs={ 6 }>*/ }
            {/*        <ControlledTextField fieldName="email"*/ }
            {/*                             label="Email"*/ }
            {/*                             rules={ {*/ }
            {/*                                 pattern: {*/ }
            {/*                                     value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,*/ }
            {/*                                     message: 'Введите корректный адрес почты',*/ }
            {/*                                 }*/ }
            {/*                             } }*/ }
            {/*                             fullWidth*/ }
            {/*        />*/ }
            {/*    </Grid>*/ }
            {/*</Grid>*/ }

        </>
    );
};

export default React.memo( ContactDataForm );