import React from 'react';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import CardRow from "./CardElements/CardRow";
import CardBase from "./CardElements/CardBase";
import CardTitle from "./CardElements/CardTitle";
import CardSubtitle from "./CardElements/CardSubtitle";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";


const PassportCard = ({ passportObj, errorMessage, countryObj }) => {
    const { spacing } = useTheme();
    const {
        from_date,
        series,
        num,
        issued_by,
        issuer_code
    } = passportObj ?? {}

    const {
        alpha3,
        name,
    } = countryObj ?? {}

    const seriesNum = (series || num) && `${ series } ${ num }`

    const titleSuffix = alpha3 === "rus" ? " РФ" : ` ${ alpha3.toUpperCase() }`

    return (
        <CardBase>
            <CardTitle title={ "Паспорт" + titleSuffix }
                       icon={ errorMessage && <WarningRoundedIcon color="warning"/> }/>
            <CardSubtitle subtitle={ seriesNum || errorMessage }/>

            { !errorMessage && <Grid container flex flexDirection="column" gap={ spacing( 3 ) }>
                {
                    alpha3 !== "rus" &&
                    <CardRow title="Гражданство">{ name || "Не указано" }</CardRow>
                }
                {
                    alpha3 === "rus" &&
                    <>
                        <CardRow title="Выдан">{ issued_by || "Не указано" }</CardRow>
                        <CardRow title="Код подразделения">{ issuer_code || "Не указано" }</CardRow>
                    </>

                }
                <CardRow title="Дата выдачи">{ from_date || "Не указано" }</CardRow>
            </Grid> }

        </CardBase>
    );
};

export default PassportCard;