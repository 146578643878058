import React from 'react';
import CardBase from "./CardElements/CardBase";
import CardRow from "./CardElements/CardRow";
import CardTitle from "./CardElements/CardTitle";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import CardSubtitle from "./CardElements/CardSubtitle";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';


const ContactsCard = (
    { rest_phone_numbers = [], emails = [], primary_phone_number, errorMessage }
) => {
    const { spacing } = useTheme();
    return (
        <CardBase>
            <CardTitle title="Контакты"
                       icon={ errorMessage && <WarningRoundedIcon color="warning"/> }/>
            <Grid container direction="row" spacing={ spacing( 2 ) }>
                <Grid item>
                    { !errorMessage && <LocalPhoneIcon/> }
                </Grid>
                <Grid item>
                    <CardSubtitle subtitle={ errorMessage || primary_phone_number }/>
                </Grid>
            </Grid>

            <Grid container flex flexDirection="column" gap={ spacing( 3 ) }>

                { rest_phone_numbers.length !== 0 && <CardRow title="Дополнительные номера">
                    { rest_phone_numbers.map( (number, i) => <div key={ i }>{ number }</div> ) }
                </CardRow> }

                { emails.length !== 0 && <CardRow title={ "Почта" }>
                    { emails.map( (email, i) => <div key={ i }>{ email }</div> ) }
                </CardRow> }

            </Grid>


        </CardBase>
    );
};

export default ContactsCard;