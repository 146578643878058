import React, { useContext, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { FormControlLabel, Switch } from "@mui/material";
import ColorModeContext from "../store/colorMode-context";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Button from "@mui/material/Button";
import LoadingContext from "../store/loading-context";
import AuthContext from "../store/auth-context";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import StickyPageHeader from "../components/Layout/UI/InterfaceElements/StickyPageHeader";
import { isDevMode } from "../utils/helperFunctions";


const TabPanel = props => {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel"
             hidden={ value !== index }
             id={ `simple-tabpanel-${ index }` }
             aria-labelledby={ `simple-tab-${ index }` }
             { ...other }>
            { value === index && (
                <Box sx={ { p: 3 } }>
                    { children }
                </Box>
            ) }
        </div>
    );
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const SettingsPage = () => {
    const [value, setValue] = useState( 0 );
    const { toggleColorMode, isDarkMode } = useContext( ColorModeContext )
    const { isLoading, setIsLoading } = useContext( LoadingContext )
    const { accessToken } = useContext( AuthContext )
    const theme = useTheme()

    const handleChange = (event, newValue) => {
        setValue( newValue );
    };

    return (
        <>
            <StickyPageHeader title="Настройки"/>
            <Tabs value={ value } onChange={ handleChange } aria-label="Settings tabs">
                <Tab label="Интерфейс" index={ 0 }/>
                { isDevMode && <Tab label="Системные" index={ 1 }/> }

            </Tabs>
            <TabPanel index={ 0 } value={ value }>
                <FormControlLabel
                    // value="end"
                    checked={ isDarkMode }
                    onChange={ toggleColorMode }
                    control={ <Switch color="primary"/> }
                    label="Темная тема"
                    labelPlacement="end"
                />
            </TabPanel>
            { isDevMode && <TabPanel index={ 1 } value={ value }>

                <Grid container
                      flex
                      flexDirection="column"
                      width="250px"
                      gap={ theme.spacing( 2 ) }>
                    <div>
                        Приложение работает в режиме <b>{ process.env.NODE_ENV }</b>.
                    </div>
                    <div>
                        API root: <b>{ process.env.REACT_APP_API_URL }</b>
                    </div>
                    <Button
                        onClick={ () => navigator.clipboard.writeText( accessToken ) }
                        startIcon={ <ContentCopyIcon fontSize="small"/> }>
                        Скопировать токен
                    </Button>

                    <FormControlLabel
                        control={ <Switch
                            checked={ isLoading }
                            onChange={ () => {
                                setIsLoading( prevState => !prevState )
                            } }
                            inputProps={ { 'aria-label': 'controlled' } }
                        /> }
                        label="Оторбазить загрузку"
                        labelPlacement="end"
                    />

                </Grid>


            </TabPanel> }


        </>
    );
};

export default SettingsPage;