import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";

const CardRow = ({ title, spacing = 1, children, dense = false }) => {

    const { spacing: themeSpacing, palette } = useTheme();

    return <Grid item container flex flexDirection={ dense ? "row" : "column" }
                 sx={
                     { ...dense && { pb: themeSpacing( spacing ) } }
                 }
    >
        <Typography variant="body1"
                    sx={ {
                        paddingBottom: dense ? themeSpacing( 1 ) : themeSpacing( spacing ),
                        ...dense && { pr: themeSpacing( 1.5 ) }
                    } }
                    color={ palette.text.secondary }>
            { title }
        </Typography>
        { children }
    </Grid>
}

export default CardRow;