import React from 'react';
import CardBase from "./CardElements/CardBase";
import CardTitle from "./CardElements/CardTitle";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import CardSubtitle from "./CardElements/CardSubtitle";
import PasswordInputField from "../Forms/FormFields/PasswordInputField";
import PasswordRepeatField from "../Forms/FormFields/PasswordRepeatField";
import PasswordRequirements from "../Forms/FormFields/PasswordRequirements";
import { FormProvider, useForm } from "react-hook-form";
import useApi from "../../customHooks/useApi";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";

const ChangePasswordCard = () => {
    const { spacing } = useTheme();
    const { makeRequest, isSubmitting } = useApi( "api/user/account/", "PATCH" )
    // Snackbar hook
    const { enqueueSnackbar } = useSnackbar()

    const methods = useForm( {
        defaultValues: {
            password: "",
            password2: ""
        },
        mode: "onChange"
    } )

    const handleSubmit = async (data) => {
        try {
            await makeRequest( data )
            enqueueSnackbar( "Пароль изменен", { variant: "success" } )
            methods.reset()
        } catch (e) {

        }
    }

    let errorMessage;
    return (
        <>
            <CardBase>
                <CardTitle title="Безопасность"
                           icon={ errorMessage && <WarningRoundedIcon color="warning"/> }/>
                <CardSubtitle subtitle={ errorMessage || "Сменить пароль" }/>

                <Grid container flex flexDirection="row" spacing={ spacing( 2 ) }>
                    <Grid container xs={ 12 } md={ 6 } item flex flexDirection="column" gap={ spacing( 1 ) }>
                        <FormProvider { ...methods }>
                            <PasswordInputField/>
                            <PasswordRepeatField watch={ methods.watch }/>
                            <LoadingButton variant="contained"
                                           disabled={ !methods.formState.isValid }
                                           onClick={
                                               methods.handleSubmit( handleSubmit )
                                           }
                                           loading={ isSubmitting }>
                                <span>Сменить пароль</span>
                            </LoadingButton>

                        </FormProvider>
                    </Grid>
                    <Grid item xs={ 12 } md={ 6 }>
                        <PasswordRequirements password={ methods.watch( "password" ) }/>
                    </Grid>
                </Grid>
            </CardBase>
        </>

    );
};

export default ChangePasswordCard;