import React, { useEffect, useState } from 'react';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

const CardBase = ({
                      children,
                      disableElevation = true,
                      hoverHandler,
                      ...props
                  }) => {
    const [cardHovered, setCardHovered] = useState( false )

    useEffect( () => {
        if (hoverHandler) {
            hoverHandler( cardHovered )
        }
    }, [cardHovered, hoverHandler] )

    return (
        <Card
            elevation={ cardHovered && !disableElevation ? 5 : 2 }
            // type="outlined"
            onMouseOver={ () => setCardHovered( true ) }
            onMouseLeave={ () => setCardHovered( false ) }
            { ...props }>
            <CardContent>
                { children }
            </CardContent>
        </Card>
    )
};

export default CardBase;