import React, { StrictMode } from 'react';
import Layout from "./components/Layout/Layout";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LogIn from "./pages/LogIn/LogIn";
import PatientList from "./pages/Patients/PatientList";
import ErrorPage from "./pages/ErrorPage";
import PatientDetailPage from "./pages/Patients/PatientDetailPage/PatientDetailPage";
import PatientCreationForm from "./pages/Patients/PatientCreation/PatientCreationForm";
import SettingsPage from "./pages/SettingsPage";
import Account from "./pages/Account/Account";
import { AuthContextProvider } from "./store/auth-context";
import { ColorModeContextProvider } from "./store/colorMode-context";
import { LoadingContextProvider } from "./store/loading-context";
import { SnackbarProvider } from "notistack";
import { CssBaseline } from "@mui/material";
import { checkAuthLoader } from "./utils/auth";
import Appointment from "./pages/Appointment/Appointment";
import Doctors from "./pages/Doctors/Doctors";
import Prices from "./pages/Prices/Prices";

const router = createBrowserRouter( [
    {
        path: "/",
        element: <Layout/>,
        errorElement: <ErrorPage/>,
        loader: checkAuthLoader,
        children: [
            {
                path: "patients",
                element: <PatientList/>,
                loader: checkAuthLoader
            },
            {
                path: "patients/add",
                element: <PatientCreationForm/>,
                loader: checkAuthLoader
            },
            {
                path: "patients/:patientId",
                element: <PatientDetailPage/>,
                loader: checkAuthLoader
            },
            {
                path: "doctors",
                element: <Doctors/>,
                loader: checkAuthLoader
            },
            {
                path: "appointment",
                element: <Appointment/>,
                loader: checkAuthLoader
            },
            {
                path: "prices",
                element: <Prices/>,
                loader: checkAuthLoader
            },
            {
                path: "account",
                element: <Account/>,
                loader: checkAuthLoader
            },
            {
                path: "settings",
                element: <SettingsPage/>,
                loader: checkAuthLoader
            }
        ]
    },
    { path: "/login", element: <LogIn/> }
] )


function App() {
    return (
        <StrictMode>
            <AuthContextProvider>
                <ColorModeContextProvider>
                    <LoadingContextProvider>
                        <SnackbarProvider autoHideDuration={ 3000 } preventDuplicate
                                          anchorOrigin={ { horizontal: "right", vertical: "bottom" } }>
                            <CssBaseline/>
                            <RouterProvider router={ router }/>
                        </SnackbarProvider>
                    </LoadingContextProvider>
                </ColorModeContextProvider>
            </AuthContextProvider>
        </StrictMode>
    )
}

export default App;
