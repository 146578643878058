import React from "react";
import { List } from "@mui/material";
import ListItemLink from "./ListItemLink/ListItemLink";


const DrawerMenuList = ({ menuItemsList, open }) => {
    return (
        <List>
            { menuItemsList.map( obj => (
                <ListItemLink
                    open={open}
                    icon={ obj.icon }
                    primary={ obj.title }
                    to={ obj.link ? obj.link : "/" }
                    key={ obj.id }
                />
            ) ) }
        </List>
    )
};

export default DrawerMenuList;