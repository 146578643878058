import React, { useState } from 'react';
import CardBase from "../../Cards/CardElements/CardBase";
import CardTitle from "../../Cards/CardElements/CardTitle";
import Grid from "@mui/material/Grid";
import CardRow from "../../Cards/CardElements/CardRow";
import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { FormProvider, useForm } from "react-hook-form";
import EditFormDialog from "../../Forms/formsArrangement/EditFormDialog";
import { useSnackbar } from "notistack";
import DiscountDataForm from "../../Forms/formsArrangement/DiscountDataForm";
import { convertDayjsToStrings } from "../../../utils/helperFunctions";
import useApi from "../../../customHooks/useApi";
import dayjs from "dayjs";
import EngineeringIcon from "@mui/icons-material/Engineering";
import { Typography } from "@mui/material";

const createDiscountRow = (discountObj) => {
    const { class_type, from_date, thru_date } = discountObj ?? {}
    return (
        <CardRow key={ class_type.id } title={ class_type.title }>
            { `Действует с ${ from_date }` + (!!thru_date ? ` по ${ thru_date }` : "") }
        </CardRow>
    )
}
const inProgress = (
    <Grid container flex flexDirection="row" alignItems="center" spacing={ 2 } sx={ { color: "rgba(0, 0, 0, 0.38)" } }>
        <Grid item>
            <EngineeringIcon/>
        </Grid>
        <Grid item>
            <Typography>Раздел в разработке</Typography>
        </Grid>
    </Grid>)


const DiscountInfo = ({
                          discountsArray,
                          patientId,
                          reloadTrigger
                      }) => {
    const { palette } = useTheme();
    const disabledColor = { color: palette.text.disabled }
    const { enqueueSnackbar } = useSnackbar();

    // const [isHovered, setIsHovered] = useState( false )
    const [isDialogOpen, setIsDialogOpen] = useState( false )

    const endpoint = "api/med/patients/" + patientId + "/add_discount/";
    const { makeRequest } = useApi( endpoint, "POST" );


    const methods = useForm( {
        defaultValues: {
            class_type: "",
            from_date: dayjs().format( 'YYYY-MM-DD' ),
            thru_date: null
        },
        mode: "onBlur",
        reValidateMode: "onChange",
        shouldFocusError: false
    } );

    const cancelHandler = () => {
        methods.reset();
        setIsDialogOpen( false );
    }

    const submitHandler = async (data) => {
        const preparedData = convertDayjsToStrings( data );

        console.log( preparedData )
        try {
            const resData = await makeRequest( preparedData );
            // display notifications
            reloadTrigger();
            enqueueSnackbar( resData?.message, { variant: "success" } );
            setIsDialogOpen( false );
        } catch (error) {
            const { response, body } = error;
            enqueueSnackbar(
                `Ошибка ${ response.status }.${ response.statusText }`,
                { variant: "error" }
            );
            if (!!body?.class_type) {
                enqueueSnackbar( "Необходимо выбрать тип скидки" )
            }
            console.log( "Server side form errors", body );
        }
    }

    const actionButton = <Button endIcon={ <AddCircleIcon/> }
                                 disabled
                                 onClick={ () => {
                                     setIsDialogOpen( true )
                                 } }>
        Добавить
    </Button>

    return (
        <>
            <CardBase
                // hoverHandler={ (isCardHovered) => {
                //     setIsHovered( isCardHovered )
                // } }
                disableElevation={ true }>

                <CardTitle title="Скидки" icon={ actionButton } sx={ disabledColor }/>

                <Grid container flex flexDirection="column" gap={ 3 }>
                    {
                        discountsArray.length > 0
                            ? discountsArray.map( createDiscountRow )
                            : inProgress
                    }
                </Grid>

            </CardBase>
            <FormProvider { ...methods }>
                <EditFormDialog open={ isDialogOpen }
                                title="Добавить скидку"
                                onCancel={ cancelHandler }
                                onSubmit={ methods.handleSubmit( submitHandler ) }
                                // isSubmitting={ isLoading }
                                formComponent={ <DiscountDataForm/> }
                                contentText="Описание"
                />
            </FormProvider>
        </>

    );
};

export default DiscountInfo;