import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from "react-router-dom";
import StickyPageHeader from "../../../components/Layout/UI/InterfaceElements/StickyPageHeader";
import useData from "../../../customHooks/useData";
import Grid from "@mui/material/Grid";
import PassportInfo from "../../../components/InfoPanels/PersonDetailPanels/PassportInfo";
import ContactsInfo from "../../../components/InfoPanels/PersonDetailPanels/ContactsInfo";
import SnilsInfo from "../../../components/InfoPanels/PersonDetailPanels/SnilsInfo";
import AddressesInfo from "../../../components/InfoPanels/PersonDetailPanels/AddressesInfo";
import PersonalInfo from "../../../components/InfoPanels/PersonDetailPanels/PersonalInfo";
import { calcAge } from "../../../utils/helperFunctions";
import { useSnackbar } from "notistack";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Button from "@mui/material/Button";
import Tooltip from '@mui/material/Tooltip';
import Tabs from "../../../components/Layout/UI/InterfaceElements/Tabs/Tabs";
import SystemInfo from "../../../components/InfoPanels/PersonDetailPanels/SystemInfo";
import { patientsUri } from "../../../utils/uris";
import Alert from '@mui/material/Alert';
import MedInfo from "../../../components/InfoPanels/PersonDetailPanels/MedInfo";
import DiscountInfo from "../../../components/InfoPanels/PersonDetailPanels/DiscountInfo";


const PatientDetailPage = () => {
    const params = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const [reload, setReload] = useState( 0 )

    const handleReload = () => {
        setReload( prevState => prevState + 1 )
    }

    const {
        data: patientData
    } = useData( patientsUri + params.patientId + "/",
        null,
        null,
        [reload]
    )
    console.log( "PatientDetailPage data:", patientData )

    const medInfo = patientData?.patient_info


    const copyPatientDataHandler = useCallback( () => {
        if (patientData) {
            const { full_name, birth_date, primary_phone_number } = patientData
            const hasPaperCard = medInfo?.paper_card ? "ЕСТЬ" : "НЕТ"
            const clipData = `${ full_name }\t${ calcAge( birth_date ) }\t'${ primary_phone_number }\t${ hasPaperCard }`
            navigator.clipboard.writeText( clipData ).then( () => {
                    enqueueSnackbar( "Данные для таблицы скопированы",
                        { variant: "success" } )
                }
            )
        }
    }, [enqueueSnackbar, medInfo, patientData] )

    const button = <Tooltip title="Скопировать данные пациента" arrow>
        <Button onClick={ copyPatientDataHandler }>
            <ContentCopyIcon/>
        </Button>
    </Tooltip>

    const patientBasicInfo = useMemo( () => (
        <Grid container flex flexDirection="column" gap={ 2 }>
            { medInfo?.banned && <Grid item>
                <Alert severity="error">Пациент в черном списке</Alert>
            </Grid> }

            <Grid item container flex flexDirection="row" spacing={ 2 }>
                <Grid item xs={ 12 } md={ 6 }>
                    <PersonalInfo { ...patientData }/>
                </Grid>
                <Grid item xs={ 12 } md={ 6 }>
                    <ContactsInfo { ...patientData }/>
                </Grid>
            </Grid>
            <Grid item container flex flexDirection="row" spacing={ 2 }>
                <Grid item xs={ 12 } md={ 6 }>
                    <MedInfo { ...medInfo } reloadTrigger={ () => handleReload() }/>
                </Grid>
                <Grid item xs={ 12 } md={ 6 }>
                    <DiscountInfo discountsArray={ patientData?.patient_discounts }
                                  patientId={ patientData?.id }
                                  reloadTrigger={ () => handleReload() }/>
                < /Grid>
            </Grid>

        </Grid>), [patientData, medInfo] )

    const patientDocs = useMemo( () => (
        <Grid container flex flexDirection="column" spacing={ 2 }>

            <Grid item container flexDirection="row" spacing={ 2 }>
                <Grid item xs={ 12 } md={ 6 }>
                    <PassportInfo { ...patientData }/>
                </Grid>
                <Grid item xs={ 12 } md={ 6 }>
                    <SnilsInfo { ...patientData }/>
                </Grid>
            </Grid>

            <Grid item container spacing={ 2 } flex flexDirection="row">
                <Grid item xs={ 12 }>
                    <AddressesInfo { ...patientData }/>
                </Grid>
            </Grid>

        </Grid>), [patientData] )

    const patientMeta = useMemo( () => (
        <SystemInfo { ...patientData }/>
    ), [patientData] )

    const patientInfoTabs = [
        ["Основное", patientBasicInfo],
        ["Документы", patientDocs],
        ["Прочее", patientMeta]
    ]


    return (
        <>
            {
                !!patientData &&
                <>
                    <StickyPageHeader
                        title={ patientData.full_name }
                        button={ button }/>

                    <Tabs tabsArr={ patientInfoTabs } initTab={ patientInfoTabs[0][0] }/>

                </>
            }
        </>

    );
};

export default PatientDetailPage;