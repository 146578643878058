import React from 'react';
import ContactsCard from "../../Cards/ContactsCard";

const ContactsInfo = ({ rest_phone_numbers = [], emails = [], primary_phone_number, errorMessage }) => {
    let error = errorMessage;
    if (!primary_phone_number || primary_phone_number === "None") {
        error = "Основной номер не указан"
        if (rest_phone_numbers.length === 0 && emails.length === 0) {
            error = "Не указаны"
        }
    }

    return <ContactsCard rest_phone_numbers={ rest_phone_numbers } emails={ emails }
                         primary_phone_number={ primary_phone_number } errorMessage={ error }/>
}

export default ContactsInfo;