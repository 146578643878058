import React from 'react';
import StickyPageHeader from "../../components/Layout/UI/InterfaceElements/StickyPageHeader";
import NotRealizedPage from "../../components/NotRealizedPage";

const Prices = () => {
    return (
        <>
            <StickyPageHeader title="Справочник цен"/>
            <NotRealizedPage/>
        </>
    );
};

export default Prices;