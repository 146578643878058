import React from 'react';
import StickyPageHeader from "../../components/Layout/UI/InterfaceElements/StickyPageHeader";
import useData from "../../customHooks/useData";
import Box from "@mui/material/Box";
import AccountInfo from "../../components/InfoPanels/AccountInfo";
import PersonalInfo from "../../components/InfoPanels/PersonDetailPanels/PersonalInfo";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import UserGroupsInfo from "../../components/InfoPanels/UserGroupsInfo";
import ChangePasswordCard from "../../components/Cards/ChangePasswordCard";

const Account = () => {

    const { data } = useData( "api/user/account/" )
    console.log( data )
    const { spacing } = useTheme();

    return (
        <>
            <StickyPageHeader title="Мой аккаунт"/>
            <Box>
                {
                    data && <>
                        <Grid container flex flexDirection="column" gap={ spacing( 2 ) }>
                            <Grid container flex flexDirection="row" spacing={ spacing( 2 ) }>
                                <Grid item md={ 6 } xs={ 12 }>
                                    <AccountInfo { ...data }/>
                                </Grid>
                                <Grid item md={ 6 } xs={ 12 }>
                                    <PersonalInfo { ...data.person }/>
                                </Grid>
                            </Grid>
                            <Grid item xs={ 12 }>
                                <ChangePasswordCard/>

                            </Grid>
                            <Grid item xs={ 12 }>
                                <UserGroupsInfo groups={ data.groups }/>
                            </Grid>
                        </Grid>


                    </>

                }


            </Box>
        </>
    );
};

export default Account;