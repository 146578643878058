import React, { useState } from 'react';
import CardBase from "../../Cards/CardElements/CardBase";
import CardTitle from "../../Cards/CardElements/CardTitle";
import Grid from "@mui/material/Grid";
import CardRow from "../../Cards/CardElements/CardRow";
import { useTheme } from "@mui/material/styles";
import EditIcon from '@mui/icons-material/Edit';
import Button from "@mui/material/Button";
import EditFormDialog from "../../Forms/formsArrangement/EditFormDialog";
import { FormProvider, useForm } from "react-hook-form";
import useApi from "../../../customHooks/useApi";
import { useSnackbar } from "notistack";
import MedDataForm from "../../Forms/MedDataForm";

const MedInfo = ({ comment, paper_card, banned, id, reloadTrigger }) => {
    const { spacing, palette } = useTheme();
    // Snackbar hook
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const methods = useForm( {
        defaultValues: {
            banned: banned,
            comment: comment || "",
            paper_card: paper_card
        },
        mode: "onBlur",
        reValidateMode: "onChange",
        shouldFocusError: false
    } );
    const endpoint = "api/med/patient-roles/" + id + "/"
    const { makeRequest, isLoading } = useApi( endpoint, "PATCH" );

    const [isHovered, setIsHovered] = useState( false )
    const [isDialogOpen, setIsDialogOpen] = useState( false )

    const actionButton = <Button endIcon={ <EditIcon/> }
                                 onClick={ () => {
                                     setIsDialogOpen( true )
                                 } }
                                 sx={ {
                                     ...(!isHovered && { color: palette.text.disabled })
    } }
    >
        Редактировать
    </Button>

    const submitHandler = async (data) => {
        try {
            console.log( data )
            // patch data
            const resData = await makeRequest( data );
            // display notifications

            console.log( "responseData", resData )

            enqueueSnackbar( "Данные успешно обновлены", { variant: "success" } );
            enqueueSnackbar( "Обновляется страница пациента...", { key: "patientPage" } );
            // refresh page after successful creation
            reloadTrigger();
            closeSnackbar( "patientPage" );
            setIsDialogOpen( false )
        } catch (error) {
            const { response, body } = error;
            enqueueSnackbar(
                `Ошибка ${ response.status }.${ response.statusText }`,
                { variant: "error" }
            );
            console.log( "Server side form errors", body );
        }
    };

    return (
        <>
            <CardBase hoverHandler={
                (isCardHovered) => {
                    setIsHovered( isCardHovered )
                } }
                      disableElevation={ false }>

                <CardTitle title="Данные пациента" icon={ actionButton }/>
                <Grid container flex flexDirection="column" gap={ spacing( 3 ) }>
                    <CardRow title="Бумажная медкарта пациента">
                        { paper_card ? "Есть" : "Отсутствует" }
                    </CardRow>
                    {
                        !!comment &&
                        <CardRow title="Комментарий">
                            { comment }
                        </CardRow>
                    }
                </Grid>
            </CardBase>

            <FormProvider { ...methods }>
                <EditFormDialog open={ isDialogOpen }
                                title="Редактировать данные пациента"
                                onClose={ () => setIsDialogOpen( false ) }
                                onCancel={ () => setIsDialogOpen( false ) }
                                onSubmit={ methods.handleSubmit( submitHandler ) }
                                isSubmitting={ isLoading }
                                formComponent={ <MedDataForm/> }
                />
            </FormProvider>
        </>
    );
};

export default MedInfo;