import { useCallback, useState } from 'react';
import useApi from "./useApi";

const useFieldApiValidation = (endpoint = "", baseUrl = `${ process.env.REACT_APP_API_URL }`) => {

    const [previousValue, setPreviousValue] = useState( "" )
    const [previousReturn, setPreviousReturn] = useState( null )
    const { makeRequest } = useApi( endpoint, "POST", baseUrl )
    const [isLoading, setIsLoading] = useState( false )


    const validateField = useCallback( async (value) => {
            console.log( "validateField triggered" )

            if (value === previousValue) {
                return previousReturn
            }
            setPreviousValue( value )

            let returnedValue;
            try {
                setIsLoading( true )
                await makeRequest( value )
                returnedValue = true

            } catch (e) {
                returnedValue = e.body
            } finally {
                setIsLoading( false )
            }

            setPreviousReturn( returnedValue )
            console.log( "validateField returns", returnedValue )
            return returnedValue

        },
        [makeRequest, previousReturn, previousValue] )

    return { validateField, isLoading }
};

export default useFieldApiValidation;