import React, { forwardRef } from "react";
import { IMaskInput } from "react-imask";

export const RussianPassportIssuerCodeMask = forwardRef( (props, ref) => {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            { ...other }
            mask="000-000"
            unmask={ false }
            inputRef={ ref }
            onAccept={ (val) => {
                return onChange( { target: { name: props.name, value: val } } );
            } }
            overwrite
        />
    );
} );

export const RussianPassportSeriesNumMask = forwardRef( (props, ref) => {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            { ...other }
            mask="00 00 000000"
            unmask={ true }
            inputRef={ ref }
            onAccept={ (val) => {
                // console.log( val )
                // setValue(val)
                return onChange( { target: { name: props.name, value: val } } );
            } }
            overwrite
        />
    );
} );

export const PhoneNumberMask = forwardRef( (props, ref) => {
    const { onChange, ...other } = props;
    // const [value, setValue] = useState("")

    return (
        <IMaskInput
            { ...other }
            mask="(000) 000-00-00"
            placeholder="(999) 000-00-00"
            unmask={ true }
            inputRef={ ref }
            lazy={ true }
            onAccept={ (val) => {
                return onChange( { target: { name: props.name, value: val } } );
            } }
            overwrite
        />
    );
} );

export const RussianSnilsMask = forwardRef( (props, ref) => {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            { ...other }
            mask="000-000-000 00"
            unmask={ true }
            inputRef={ ref }
            onAccept={ (val) => {
                return onChange( { target: { name: props.name, value: val } } );
            } }
            overwrite
        />
    );
} );

export const RussianPhoneNumberMask = forwardRef( (props, ref) => {
    const { onChange, ...other } = props;
    // const [value, setValue] = useState("")

    return (
        <IMaskInput
            { ...other }
            // mask={/^\S*@?\S*$/}
            mask="{+7} (000) 000-00-00"
            lazy={ true }
            placeholder="+7 (999) 000-00-00"
            unmask={ true }
            inputRef={ ref }
            onAccept={ (val) => {
                return onChange( { target: { name: props.name, value: val } } );
            } }
            // overwrite
        />
    );
} );