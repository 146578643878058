import React, { useEffect, useState } from 'react';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import Divider from "@mui/material/Divider";
import { useTheme } from "@mui/material/styles";

let renderCount = 0

const FormsAccordion = ({ formsArray }) => {

    const [expandedSteps, setExpandedSteps] = useState( [] );
    const { spacing } = useTheme();

    const handleAccordion = (id) => {
        if (expandedSteps.includes( id )) {
            setExpandedSteps( prevState => prevState.filter( (value) => value !== id ) );
        } else {
            setExpandedSteps( prevState => [...prevState, id] );
        }
    }

    useEffect( () => {
        const stepsIds = formsArray.map( form => form.id )
        setExpandedSteps( stepsIds )
    }, [formsArray] )


    renderCount++;
    console.log( `FormsAccordion.js renderCount`, renderCount );
    return (
        <>
            {
                formsArray.map( formObject => <Accordion expanded={ expandedSteps.includes( formObject.id ) }
                                                         onChange={ () => handleAccordion( formObject.id ) }
                                                         key={ formObject.id } >
                    <AccordionSummary
                        expandIcon={ <ExpandMoreIcon/> }
                        aria-controls={ formObject.id + "-content" }
                        id={ formObject.id + "-header" }
                    >
                        <Grid container flex flexDirection="row" alignItems="baseline" gap={ spacing( 2 ) }>
                            <Typography variant="subtitle1" fontSize={18}>
                                { formObject.title }
                            </Typography>
                            {
                                !expandedSteps.includes( formObject.id ) &&
                                <Typography sx={ { color: 'text.secondary' } }>
                                    { formObject?.details }
                                </Typography>
                            }
                        </Grid>


                    </AccordionSummary>

                    <Divider flexItem component="div" role="presentation"/>

                    <AccordionDetails sx={{pt: spacing(3)}}>
                        {/*<div style={ { paddingTop: spacing( 1 ) * 8 + "px" } }>*/}

                            { !!formObject.component &&
                                <formObject.component { ...formObject.props } /> }
                        {/*</div>*/}
                    </AccordionDetails>
                </Accordion> )
            }
        </>

    );
};

export default React.memo( FormsAccordion );