import { useCallback, useContext, useEffect, useState } from "react";
import AuthContext from "../store/auth-context";
import { useNavigate } from "react-router-dom";
import LoadingContext from "../store/loading-context";

/**
 * Custom hook to fetch data from an API endpoint and manage loading and error states.
 *
 * @param {string} endpoint - The endpoint to fetch data from.
 * @param {string|null} searchQuery - An optional search query to include in the request URL.
 * @param {int|null} page - An optional page number for pagination.
 * @param {string} baseUrl - The base URL for the API.
 * @param {RequestInit|null} fetchInit - An optional object containing custom options for the fetch request.
 * @param {Array} deps - Dependencies array that can trigger re-fetching data when it changes.
 * @returns {Object} An object containing the data, error, and loading state.
 */
const useData = (
    endpoint = "",
    searchQuery = null,
    page = null,
    deps = [],
    baseUrl = `${ process.env.REACT_APP_API_URL }`,
    fetchInit = null,
) => {

    const [data, setData] = useState( null ); // The data returned from the API.
    const [error, setError] = useState( null ); // Any error that occurred during the API call.
    const [isLoading, setIsLoading] = useState( false ); // Whether the API call is currently in progress.

    // Get the authentication context and logout function.
    const { authHeader, logout } = useContext( AuthContext );
    // Get the loading context to show/hide the loading spinner.
    const loadingContext = useContext( LoadingContext );
    // Get the navigate function from the router.
    const navigate = useNavigate();

    const fetchData = useCallback( () => {


        let init = fetchInit;
        if (!init) {
            init = {
                method: "GET",
                headers: { ...authHeader }

            }
        }

        // Only make the API call if both baseUrl and endpoint are provided.
        if (!!!baseUrl && !!!endpoint) {
            return;
        }
        // If searchQuery provided, but it is an empty string
        // do not return anything.
        // if (searchQuery === "") {
        //     return;
        // }

        let fullUrl = baseUrl + endpoint;

        if (!!searchQuery) {
            fullUrl += "?search=" + searchQuery;
        } else if (!!page) {
            fullUrl += "?page=" + page
        }

        loadingContext.setIsLoading( true );
        setIsLoading( true );


        console.log( "useData fetching", fullUrl );

        const abortController = new AbortController();

        fetch( fullUrl, { ...init, signal: abortController.signal } )
            .then( response => {
                const json = response.json()
                if (response.ok) {
                    return json
                } else if (
                    response.status === 401 &&
                    baseUrl === process.env.REACT_APP_API_URL
                ) {
                    // If the API returns a 401 (Unauthorized) status code, and we're using the default API URL,
                    // log the user out and redirect them to the login page.
                    logout();
                    navigate( '/login' );
                } else {
                    const errorObj = {
                        response: {
                            ok: response.ok,
                            status: response.status,
                            statusText: response.statusText,
                            url: response.url
                        },
                        body: json
                    }
                    setError( errorObj )
                    throw errorObj;
                }
            } )
            .then( json => {
                setData( json );
                // setCachedData( json );
            } )
            .catch( error => {
                console.log( "useData error object:", error )
            } )
            .finally( () => {
                setIsLoading( false )
                loadingContext.setIsLoading( false );
            } );

        return () => {
            abortController.abort();
        };


    }, [fetchInit, baseUrl, endpoint, searchQuery, authHeader, logout, navigate, page] )

    // This optimized version uses useCallback to memoize the fetchData function and prevents it
    // from being redefined on every render. It also adds a dependencies parameter
    // to the hook, which can trigger re-fetching data when it changes. Finally, it uses
    // the AbortController to cancel the ongoing fetch request if the component unmounts before
    // the request is completed.
    useEffect( () => {

        fetchData();

    }, [fetchData, ...deps] );

    return {
        data: data,
        error: error,
        isLoading: isLoading
    };
};

export default useData;
