import Container from '@mui/material/Container';
import Copyright from "../../components/Layout/UI/InterfaceElements/Copyright";
import Grid from "@mui/material/Grid";
import { lazy, Suspense } from 'react';
import { Skeleton } from "@mui/material";
import Paper from "@mui/material/Paper";


const LoginForm = lazy( () => import("../../components/Forms/loginForm") );

const logo = (<Container disableGutters maxWidth="xs">
    <Grid className="wrapper" maxWidth="xs" sx={ { pr: 7, pl: 7, pt: "4vh", minHeight: 50 } }>
        <img src="/img/mfLogo.svg" alt="Логотип" width="100%" loading="lazy"/>
    </Grid>
</Container>)

const fallback = (<Paper>
    <Grid container flex flexDirection="column" gap={ 3.5 } alignItems="center" padding={ 2 }>
        <Skeleton variant="circular" width={ 42 } height={ 42 }
                  sx={ { mb: 4 } }
                  animation="wave"/>
        <Skeleton variant="rounded" width="100%" height={ 48 }/>
        <Skeleton variant="rounded" width="100%" height={ 55 }/>
        <Skeleton variant="rounded" width="100%" height={ 55 }/>
        <Skeleton variant="rounded" width="100%" height={ 36 }/>
    </Grid>
</Paper>)

export default function LogIn() {


    const containerSx = {
        height: { xs: "100svh", md: "90vh", lg: "70vh" },
        // bgcolor: palette.action.hover,
        p: 2,
        // pb: 2
    }

    return (
        <Grid container flex
              flexDirection="column"
              justifyContent="space-between"
              sx={ containerSx }>
            { logo }
            <Container component="main"
                       maxWidth="xs">
                <Suspense fallback={ fallback }>
                    <LoginForm/>
                </Suspense>
            </Container>
            <Copyright/>
        </Grid>
    );
}