import React from 'react';
import ControlledTextField from "./FormFields/ControlledTextField";
import ControlledSwitchField from "./FormFields/ControlledSwitchField";
import Grid from "@mui/material/Grid";

const MedDataForm = () => {
    return (
        <Grid container flex flexDirection="column" gap={ 2 }>
            <ControlledSwitchField fieldName="paper_card"
                                   label="Есть бумажная медкарта"/>
            <ControlledSwitchField fieldName="banned"
                                   label="В черном списке"/>
            <ControlledTextField fieldName="comment"
                                 label="Комментарий к пациенту"
                                 fullWidth
                                 autoComplete='off'/>
        </Grid>

    );
};

export default MedDataForm;