import React, { useState } from 'react';
import ControlledTextField from "./ControlledTextField";
import { InputAdornment } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";

const PasswordInputField = ({ fieldName = "password", rules, ...props }) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState( false );

    const handleClickShowPassword = () => setIsPasswordVisible( (show) => !show );
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const passwordVisibleToggler = <InputAdornment position="end">
        <IconButton
            aria-label="toggle password visibility"
            onClick={ handleClickShowPassword }
            onMouseDown={ handleMouseDownPassword }
            edge="end"
        >
            { isPasswordVisible ? <VisibilityOff/> : <Visibility/> }
        </IconButton>
    </InputAdornment>

    return (
        <>
            <ControlledTextField fieldName={ fieldName }
                                 rules={ rules }
                                 InputProps={ {
                                     endAdornment: passwordVisibleToggler
                                 } }
                                 label="Пароль"
                                 { ...props }
                                 type={ isPasswordVisible ? "text" : "password" }
            />
        </>

    );
};

export default PasswordInputField;