import React, { useCallback, useState } from 'react';
import ControlledTextField from "./ControlledTextField";
import ReactDadataBox from "react-dadata-box";
import { useTheme } from "@mui/material/styles";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import { InputAdornment } from "@mui/material";
import IconButton from "@mui/material/IconButton";

const ControlledSuggestionField = React.memo(
    ({
         type,
         fieldName,
         suggestionHandler,
         onIdleOutHandler,
         iconClickHandler,
         rules,
         icon,
         ...other
     }) => {
        const [suggestionColor, setSuggestionColor] = useState( "warning" );

        const theme = useTheme()

        const memoizedSuggestionHandler = useCallback( (suggestion) => {
                console.log( "sugg", suggestion?.data )
                if (type === "fio") {
                    if (suggestion?.data.qc === "0") {
                        setSuggestionColor( "success" )
                    }
                    if (suggestion?.data.qc === "1") {
                        setSuggestionColor( "error" )
                    }
                }
                if (type === "fms_unit") {
                    if (!!suggestion?.data?.name && !!suggestion?.data?.code) {
                        setSuggestionColor( "success" )
                    }
                }

                suggestionHandler && suggestionHandler( suggestion );
            },
            [suggestionHandler, type]
        );

        const memoizedIconClickHandler = useCallback( (event) => {
            iconClickHandler( event )
        }, [iconClickHandler] )


        const styles = {
            'react-dadata__suggestion': {
                paddingTop: theme.spacing( 1 ),
                paddingBottom: theme.spacing( 1 ),
                paddingRight: theme.spacing( 2 ),
                paddingLeft: theme.spacing( 2 ),
                ...theme.typography.body1,
            },
            'react-dadata__suggestion-note': {
                fontSize: "24px !important",
                lineHeight: "18px",
                color: "#f50000",
                padding: "5px 10px",
                display: "flex"
            },
            'react-dadata__suggestions': {
                position: "absolute",
                zIndex: 10,
                backgroundColor: theme.palette.background.paper,
                boxShadow: theme.shadows[12],
                top: "100%",
                left: 0,
                right: 0,
                borderBottomRightRadius: theme.shape.borderRadius,
                borderBottomLeftRadius: theme.shape.borderRadius,
            },
        }

        return (
            <ReactDadataBox
                token="daa6d0dc40aab27358bdce979511a572a1b2a22f"
                type={ type }
                debounce={ 500 }
                onChange={ memoizedSuggestionHandler }
                customStyles={ styles }
                onIdleOut={ onIdleOutHandler }
                // forceOpenList={ true }
                customInput={ (props) => (
                    <ControlledTextField fieldName={ fieldName }
                                         InputProps={ {
                                             endAdornment: <InputAdornment position='end'>
                                                 <IconButton disabled={ !iconClickHandler }
                                                             onClick={ memoizedIconClickHandler }>
                                                     { icon || <ElectricBoltIcon color={ suggestionColor }/> }
                                                 </IconButton>
                                             </InputAdornment>
                                         } }
                                         rules={ rules }
                                         autoComplete="new-password"
                                         { ...other }
                                         { ...props }

                    />
                ) }
            />
        );
    }
)

ControlledSuggestionField.displayName = "ControlledSuggestionField"

export default ControlledSuggestionField;