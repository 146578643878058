import React, { useEffect, useState } from 'react';
import Grid from "@mui/material/Grid";
import ControlledTextField from "./FormFields/ControlledTextField";
import ControlledSelect from "./FormFields/ControlledSelect";
import ControlledDateInput from "./FormFields/ControlledDateInput";
import { useFormContext } from "react-hook-form";
import { requiredRule } from "./defaults/formsDefaults";
import ControlledSuggestionField from "./FormFields/ControlledSuggestionField";
import { useTheme } from "@mui/material/styles";
import { calcAge, isFutureDate } from "../../utils/helperFunctions";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const PersonalDataForm = ({
                              fastInput = true,
                              readOnly = false,
                              fieldsMap = {
                                  name: "name",
                                  surname: "surname",
                                  patronymic: "patronymic",
                                  gender: "gender",
                                  birth_date: "birth_date"
                              }
                          }) => {
    const [isNameFieldsShown, setIsNameFieldsShown] = useState( false )

    const theme = useTheme()
    const spacing = theme.spacing( 2 )

    const { setValue, formState } = useFormContext()

    const handlePersonSuggestion = (suggestion) => {
        const { name, surname, patronymic, gender } = suggestion.data
        setValue( fieldsMap.name, name || "" )
        setValue( fieldsMap.surname, surname || "" )
        setValue( fieldsMap.patronymic, patronymic || "" )
        setValue( fieldsMap.gender, gender?.slice( 0, 1 ).toLowerCase() || "" )
    }

    useEffect( () => {
        if (formState?.errors?.patient?.name || formState?.errors?.patient?.surname || formState?.errors?.patient?.gender) {
            setIsNameFieldsShown( true )
        }
        if (formState.errors) {
            console.log( "errors:", formState.errors )
        }
    }, [formState] )

    return (
        <Grid container flex flexDirection='column' gap={ spacing } sx={ { width: '100%' } }>

            <Grid item container flex flexDirection="row" spacing={ spacing }>
                <Grid item flex xs={ 12 }>
                    <ControlledSuggestionField fieldName="fullname"
                                               label="Быстрый ввод ФИО"
                                               type="fio"
                                               suggestionHandler={ handlePersonSuggestion }
                                               sx={ { height: "auto" } }
                                               iconClickHandler={ () => setIsNameFieldsShown( prevState => !prevState ) }
                                               icon={ isNameFieldsShown && <VisibilityOffIcon/> }/>
                </Grid>
            </Grid>


            <Grid container
                  flex
                  flexDirection="row"
                  spacing={ spacing }
                  sx={ { ...!isNameFieldsShown && { display: "none" } } }>
                <Grid item xs={ 12 } md={ 4 }>
                    <ControlledTextField
                        rules={ requiredRule }
                        fieldName={ fieldsMap.surname }
                        label="Фамилия"
                        fullWidth
                        readOnly={ readOnly }/>
                </Grid>
                <Grid item xs={ 12 } md={ 4 }>
                    <ControlledTextField
                        rules={ requiredRule }
                        fieldName={ fieldsMap.name }
                        label='Имя'
                        fullWidth
                        readOnly={ readOnly }/>
                </Grid>
                <Grid item xs={ 12 } md={ 4 }>
                    <ControlledTextField
                        fieldName={ fieldsMap.patronymic }
                        label="Отчество"
                        fullWidth
                        readOnly={ readOnly }/>
                </Grid>
            </Grid>

            <Grid container flex flexDirection="row" spacing={ spacing }>
                <Grid item xs={ 12 } md={ 4 }>
                    <ControlledDateInput
                        fieldName={ fieldsMap.birth_date }
                        label="Дата рождения"
                        rules={ {
                            ...requiredRule,
                            validate: (value) => {
                                if (isFutureDate(value)) {
                                    return "Будущая дата"
                                }
                                if (calcAge( value ) < 15) {
                                    return "Пациент младше возраста согласия (15 лет)"
                                }
                            }
                        } }
                        onChangeHandler={ (e) => {
                            console.log( e )
                            console.log( calcAge( e ) )
                        } }
                        fullWidth
                        readOnly={ readOnly }
                        disableFuture
                    />
                </Grid>
                <Grid item xs={ 12 } md={ 4 } sx={ { ...!isNameFieldsShown && { display: "none" } } }>
                    <ControlledSelect
                        fieldName={ fieldsMap.gender }
                        label="Пол"
                        rules={ requiredRule }
                        options={ {
                            m: "Мужской",
                            f: "Женский"
                        } }
                        fullWidth
                        readOnly={ readOnly }/>
                </Grid>

            </Grid>
        </Grid>
    );
};

export default React.memo( PersonalDataForm );