import React from 'react';
import { Controller, useFormContext } from "react-hook-form";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

const ControlledSelect = ({
                              fieldName,
                              rules,
                              options,
                              readOnly = false,
                              ...other
                          }) => {

    const { control } = useFormContext(); // retrieve control hook method

    return (
        <Controller
            name={ fieldName }
            control={ control }
            rules={ rules }
            render={ ({
                          field,
                          fieldState: {
                              invalid,
                              error
                          }
                      }) => <TextField select
                                       required={ !readOnly && !!rules?.required }
                                       error={ invalid }
                                       helperText={ invalid ? error.message : " " }
                                       InputProps={ { readOnly: readOnly } }
                                       { ...field }
                                       { ...other }>
                { Object.entries( options ).map( ([key, value]) => (
                    <MenuItem key={ key }
                              value={ key }>
                        { value }
                    </MenuItem>) ) }
            </TextField> }
        />
    );
};

export default ControlledSelect;