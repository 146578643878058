import React from 'react';
import CardBase from "../../Cards/CardElements/CardBase";
import CardTitle from "../../Cards/CardElements/CardTitle";
import Grid from "@mui/material/Grid";
import CardRow from "../../Cards/CardElements/CardRow";

const SystemInfo = ({ id, created_at, created_by }) => {
    return (
        <CardBase>
            <CardTitle title="Системная информация"/>
            <Grid container flex flexDirection="column" gap={ 3 }>
                <CardRow title="ID пациента в системе">{ id }</CardRow>
                <CardRow title="Создан (дата, время)">{ created_at }</CardRow>
                <CardRow title="Кем создан">{ created_by || "Не указано" }</CardRow>
            </Grid>

        </CardBase>
    );
};

export default SystemInfo;