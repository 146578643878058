import React from "react";
import DrawerMenuList from "./DrawerMenuList/DrawerMenuList";
import Divider from "@mui/material/Divider";

const DrawerMenu = ({ mainMenu, subMenu, open }) => (
    <>
        <DrawerMenuList menuItemsList={ mainMenu } open={open}/>
        {
            !!subMenu ? <>
                    <Divider/>
                    <DrawerMenuList menuItemsList={ subMenu }
                                    open={ open }/>
                </>
                : null
        }

    </>
);

export default DrawerMenu;