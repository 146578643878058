import React from 'react';
import Box from "@mui/material/Box";
import EngineeringIcon from '@mui/icons-material/Engineering';
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";

const NotRealizedPage = () => {
    const { palette: { text: { disabled } } } = useTheme()
    return (
        <Box>
            <Grid flex
                  container
                  pt={ 4 }
                  gap={ 2 }
                  flexDirection="column"
                  alignItems="center"
                  sx={ { color: disabled } }>
                <EngineeringIcon sx={ { fontSize: 78 } }/>
                <Typography variant="subtitle1">
                    Раздел в разработке
                </Typography>
            </Grid>
        </Box>
    );
};

export default NotRealizedPage;