import { Suspense, useState, lazy } from 'react';
import Button from "@mui/material/Button";
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { useNavigate } from "react-router-dom";
import useData from "../../customHooks/useData";
import StickyPageHeader from "../../components/Layout/UI/InterfaceElements/StickyPageHeader";
import { patientsUri } from "../../utils/uris";
import Grid from "@mui/material/Grid";
import SearchField from "../../components/Forms/FormFields/SearchField";
import { Skeleton } from "@mui/material";

const DataGrid = lazy(
    () => import('@mui/x-data-grid').then( module => ({ default: module.DataGrid }) )
);

const dataGridFallback = (<Grid container flex flexDirection="column" gap={ 1 }>
    { Array( 12 ).fill( 0 ).map( (_, i) => {
        return <Skeleton key={i} variant="rounded" width="100%" height={ 42 } component="div"/>
    } ) }
</Grid>)

const PatientList = () => {
    const navigate = useNavigate();
    const [paginationModel, setPaginationModel] = useState( {
        page: 0,
        pageSize: 10,
    } );
    const [searchQuery, setSearchQuery] = useState( "" )


    const {
        data, isLoading
    } = useData(
        `${ patientsUri }`,
        `${ searchQuery }`,
        paginationModel.page + 1 )

    const patients = data?.results || []
    const rowCount = data?.count

    console.log( patients )

    const columns = [
        {
            field: "surname",
            headerName: "Фамилия",
            flex: 110,
            // valueGetter: ({ row }) => row.person.surname
        },
        {
            field: "name",
            headerName: "Имя",
            flex: 110,
            // valueGetter: ({ row }) => row.person.name
        },
        {
            field: "patronymic",
            headerName: "Отчество",
            flex: 120,
            // valueGetter: ({ row }) => row.person.patronymic
        },
        {
            field: "birth_date",
            headerName: "Дата рождения",
            flex: 100,
            type: "date",
            valueGetter: ({ value }) => new Date( value ),
        },
        {
            field: "primary_phone_number",
            headerName: "Номер телефона",
            flex: 100,
            // valueGetter: ({ row }) => row.person.primary_phone_number
        },
    ]

    const handleRowClick = (params, event, details) => {
        navigate( `/patients/${ params.row.id }` )
    }

    const addPatientButton = <Button
        size="medium"
        variant="contained"
        startIcon={ <PersonAddAlt1Icon/> }
        // to="add"
        onClick={ () => {
            navigate( 'add' )
        } }
        // component={ Link }
        // to="add"
    >
        Добавить пациента
    </Button>

    const searchChangeHandler = (e) => {
        setSearchQuery( e.target.value )
    }

    return (
        <>
            <StickyPageHeader title="Справочник пациентов" button={ addPatientButton }/>
            <Grid container flex flexDirection="column" gap={ 2 }>
                <Grid item container xs={ 5 }>
                    <SearchField queryChangeHandler={ searchChangeHandler }
                                 searchQuery={ searchQuery }
                                 fullWidth
                                 clearHandler={ () => setSearchQuery( "" ) }/>
                </Grid>
                <Suspense fallback={ dataGridFallback }>

                    <Grid item>
                        <DataGrid sx={ {
                            "& .MuiDataGrid-row:hover": {
                                cursor: 'pointer'
                            }
                        } }
                                  loading={ isLoading }
                                  autoHeight
                                  rows={ patients }
                                  columns={ columns }
                                  paginationMode={ !!searchQuery ? "client" : "server" }
                                  onPaginationModelChange={ setPaginationModel }
                                  pageSizeOptions={ [10] }
                                  rowCount={ !!searchQuery ? patients.length : rowCount }
                                  paginationModel={ paginationModel }
                                  onRowClick={ handleRowClick }
                                  hideFooterSelectedRowCount
                                  disableSelectionOnClick
                                  slotProps={ {
                                      pagination: {
                                          labelDisplayedRows: ({ from, to, count, page }) => {
                                              return '' + from + '-' + to + ' из ' + count
                                          }
                                      }
                                  } }
                        />
                    </Grid>
                </Suspense>

            </Grid>
        </>
    );
};

export default PatientList;