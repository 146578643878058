import dayjs from "dayjs";

export const patientValues = {
    surname: "",
    name: "",
    patronymic: "",
    gender: "",
    birth_date: null,
    snils: ""
}

export const passportValues = {
    from_date: null,
    thru_date: null,
    fullSeriesNumber: "",
    series: "",
    num: "",
    issued_by: "",
    issuer_code: ""
}


export const defaultPatientCreationValues = {
    patient: patientValues,
    primary_phone_number: {
        country_code: 7,
        area_code: "",
        number: ""
    },
    citizenship: {
        country: "rus"
    },
    passport: passportValues,
    registration_address: "",
    registration_address_obj: null,
    living_address_equal_to_registration: true,
    living_address: "",
    living_address_obj: null
}
export const examplePatientCreationValues = {
    patient: {
        surname: "Тестов",
        name: "Тест",
        patronymic: "Тестович",
        gender: "m",
        birth_date: dayjs( new Date( 1995, 5, 14 ) )
    },
    primary_phone_number: {
        country_code: "7",
        area_code: "",
        number: "7777777777"
    },
    citizenship: {
        country: "rus"
    },
    passport: {
        from_date: dayjs( new Date( 2001, 0, 1 ) ),
        thru_date: null,
        fullSeriesNumber: "4615123456",
        issued_by: "ОТДЕЛЕНИЕМ УФМС РОССИИ ПО МОСКОВСКОЙ ОБЛ. В СТУПИНСКОМ РАЙОНЕ",
        issuer_code: "500-161"
    },
    registration_address: "улица пушкина, дом колотушкина"
}