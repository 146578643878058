import React from 'react';
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

const CardSubtitle = ({ subtitle }) => {
    const { spacing } = useTheme();
    if (!subtitle) {
        return
    }
    return (
        <Typography sx={ { fontWeight: 500, letterSpacing: 0.7, marginBottom: spacing( 3 ) } }
                    variant="subtitle1">
            { subtitle }
        </Typography>
    );
};

export default CardSubtitle;