import React from 'react';
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

const Copyright = () => {
    return (
        <Box>
            <Typography variant="body2" color="textSecondary" align="center">
                { `${ process.env.REACT_APP_APP_NAME } v${ process.env.REACT_APP_VERSION }` }
            </Typography>
        </Box>
    );
};

export default Copyright;
