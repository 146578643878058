import React from 'react';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

const CardTitle = ({ title, icon, ...typographyProps }) => {

    return (
        <>
            <Grid container flex
                  flexDirection="row"
                  alignItems="center"
                  paddingBottom={ 1 }
                  justifyContent="space-between">
                <Typography variant="h6" { ...typographyProps }>
                    { title }
                </Typography>
                { icon }

            </Grid>
            <Divider sx={ { marginBottom: 2 } }/>
        </>

    );
};

export default CardTitle;