import React from 'react';
import StickyPageHeader from "../../components/Layout/UI/InterfaceElements/StickyPageHeader";
import NotRealizedPage from "../../components/NotRealizedPage";

const Doctors = () => {
    return (
        <>
            <StickyPageHeader title="Справочник врачей"/>
            <NotRealizedPage/>
        </>
    );
};

export default Doctors;