import { ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { Link } from 'react-router-dom';
import { useTheme } from "@mui/material/styles";

const ListItemLink = ({ icon, primary, to, open }) => {
    const theme = useTheme();
    return (
        <ListItem sx={ {
            pl: 0,
            pr: 0,
        } }>
            <ListItemButton component={ Link }
                            to={ to }
            >
                { icon ? <ListItemIcon sx={ {
                    minWidth: 0,
                    pl: 0.5,
                    // mr: open ? 2 : 'auto',
                    // justifyContent: 'center',
                } }>
                    { icon }
                </ListItemIcon> : null }
                <ListItemText primary={ primary }
                              sx={ {
                                  opacity: open ? 1 : 0,
                                  pl: 2,
                                  transition: theme.transitions.create( ["opacity"],
                                      { delay: 50 }
                                  )
                              } }/>
            </ListItemButton>
        </ListItem>
    );
};

export default ListItemLink;
