import React from 'react';
import 'dayjs/locale/ru';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Controller, useFormContext } from "react-hook-form";
import dayjs from "dayjs";


const ControlledDateInput = ({
                                 fieldName,
                                 rules,
                                 onChangeHandler = (e) => {
                                 },
                                 blurHandler = (e) => {
                                 },
                                 readOnly = false,
                                 ...other
                             }) => {

    const { control } = useFormContext(); // retrieve control hook method

    return (
        <Controller name={ fieldName }
                    control={ control }
                    rules={ rules }
                    render={
                        ({
                             field: { onChange, onBlur, value, name, ref },
                             fieldState: { invalid, error }
                         }) => {
                            if (typeof value === "string" && value !== "") {
                                value = dayjs( value )
                            }
                            return <LocalizationProvider dateAdapter={ AdapterDayjs }
                                                         adapterLocale="ru">
                                <DatePicker
                                    onChange={ (e) => {
                                        onChange( e );
                                        onChangeHandler( e );
                                    } }
                                    value={ value }
                                    readOnly={ readOnly }
                                    onBlur={ (e) => {
                                        onBlur( e )
                                    } }
                                    slotProps={ {
                                        textField: {
                                            required: !readOnly && !!rules?.required,
                                            error: invalid,
                                            helperText: invalid ? error.message : " ",
                                            name: name,
                                            ref: ref
                                        }
                                    } }
                                    { ...other }
                                />
                            </LocalizationProvider>
                        }
                    }
        />
    );
};

export default React.memo( ControlledDateInput );