import React, { useState } from "react"

const LoadingContext = React.createContext( {
    isLoading: false,
    setIsLoading: () => {
    }
} )

export const LoadingContextProvider = (props) => {
    const [isLoading, setIsLoading] = useState( false )

    const contextValue = {
        isLoading: isLoading,
        setIsLoading: setIsLoading
    }

    return <LoadingContext.Provider value={ contextValue }>
        { props.children }
    </LoadingContext.Provider>
}

export default LoadingContext