import React from "react";
import { Outlet } from "react-router-dom";
import Box from "@mui/material/Box";
import { CssBaseline } from "@mui/material";
import TopBar from "./UI/InterfaceElements/TopBar/TopBar";
import SideDrawer from "./UI/InterfaceElements/SideDrawer/SideDrawer";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";


const Layout = () => {
    const [open, setOpen] = React.useState( false );
    // const navigate = useNavigate();
    //
    // useEffect( () => {
    //     navigate( "/patients" )
    // }, [] )

    return (
        <Box sx={ { display: 'flex', height: "100vh" } }>
            <CssBaseline/>
            <TopBar title={ `${ process.env.REACT_APP_APP_NAME }` }
                    drawerToggleHandler={
                        () => {
                            setOpen( prevState => !prevState )
                        }
                    }
                    isDrawerOpen={ open }
            />
            <SideDrawer open={ open }/>
            <Box component="main" sx={ {
                flexGrow: 1,
                paddingTop: 0,
                paddingBottom: 0,
                paddingRight: 1,
                height: "100vh",
                display: "flex",
                flexDirection: "column"
            } }>
                <Toolbar/>
                <Container maxWidth="md" sx={ {
                    height: "100vw",
                    overflow: "scroll",
                    marginTop: 2,
                    paddingBottom: 2,
                    flexGrow: 1,
                } }>
                    <Outlet/>

                </Container>

            </Box>

        </Box>
    )

};

export default Layout;