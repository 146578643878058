import DrawerMenu from "./DrawerMenu/DrawerMenu";
import React, { useState } from "react";
import { styled } from '@mui/material/styles';
// import Drawer from "@mui/material/Drawer";
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from "@mui/material/Toolbar";
import EventIcon from "@mui/icons-material/Event";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import SvgIcon from "@mui/material/SvgIcon";
import ListAltIcon from "@mui/icons-material/ListAlt";

const MAIN_MENU_LIST = [
    {
        id: 1,
        title: 'Пациенты',
        icon: <PermContactCalendarIcon/>,
        link: 'patients'
    },
    {
        id: 2,
        title: 'Запись',
        icon: <EventIcon/>,
        link: 'appointment'
    },
    {
        id: 3,
        title: 'Врачи',
        icon: <SvgIcon>
            <path
                d="M 12 0 C 10.0616 0 8.90125 0.295385 8.16371 0.663692 C 7.79448 0.848308 7.53233 1.04215 7.35602 1.24062 C 7.17879 1.43908 7.0671 1.73077 7.0671 1.73077 C 7.03815 1.8049 7.01891 1.88247 7.00986 1.96154 L 6.51879 8.27908 C 6.51233 8.31785 6.52525 8.38062 6.51879 8.42308 L 6.46156 9.05815 C 6.45618 9.11557 6.45618 9.17336 6.46156 9.23077 C 6.46156 12.2769 8.95387 14.7692 12 14.7692 C 15.0462 14.7692 17.5385 12.2769 17.5385 9.23077 V 9.08677 C 17.5386 9.07723 17.5386 9.06769 17.5385 9.05815 L 16.9902 1.96154 C 16.9811 1.88247 16.9619 1.8049 16.9329 1.73077 C 16.9329 1.73077 16.8212 1.43908 16.644 1.24062 C 16.4677 1.04123 16.2056 0.847385 15.8363 0.663692 C 15.0988 0.294462 13.9385 0 12 0 Z M 12 14.7692 C 5.90771 14.7692 0.923096 17.6363 0.923096 20.2209 V 24 H 23.0769 V 20.2209 C 23.0769 17.7655 18.5705 15.0609 12.8945 14.7978 C 12.5967 14.7782 12.2984 14.7687 12 14.7692 V 14.7692 Z M 12 1.84615 C 13.7539 1.84615 14.6299 2.12308 15 2.30769 C 15.1237 2.36954 15.1385 2.39354 15.1736 2.42308 L 15.5483 7.15385 C 14.6991 7.20554 13.5416 7.38462 12 7.38462 C 10.4585 7.38462 9.30187 7.20554 8.45171 7.15385 L 8.82648 2.42308 C 8.86063 2.39354 8.87633 2.36954 9.00002 2.30769 C 9.37017 2.12308 10.2462 1.84615 12 1.84615 V 1.84615 Z M 11.2496 2.76923 V 3.86585 H 10.1539 V 5.36585 H 11.2505 V 6.46154 H 12.7496 V 5.36492 H 13.8462 V 3.86585 H 12.7496 V 2.76923 H 11.2505 H 11.2496 Z M 9.23079 16.8462 L 11.5966 22.1538 H 2.76925 V 20.2209 C 2.76925 19.5332 5.1231 17.5191 9.23079 16.8462 Z M 14.7692 16.8462 C 18.8769 17.52 21.2308 19.5332 21.2308 20.2209 V 22.1538 H 12.4034 L 14.7692 16.8462 Z"/>
        </SvgIcon>,
        link: 'doctors'
    },
    {
        id: 4,
        title: 'Цены',
        icon: <ListAltIcon/>,
        link: 'prices'
    },
];

const drawerWidth = 200;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create( 'width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    } ),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create( 'width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    } ),
    overflowX: 'hidden',
    width: `calc(${ theme.spacing( 7 ) } + 1px)`,
    [theme.breakpoints.up( 'sm' )]: {
        width: `calc(${ theme.spacing( 8 ) } + 1px)`,
    },
});


const Drawer = styled( MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' } )(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin( theme ),
            '& .MuiDrawer-paper': openedMixin( theme ),
        }),
        ...(!open && {
            ...closedMixin( theme ),
            '& .MuiDrawer-paper': closedMixin( theme ),
        }),
    }),
);

const SideDrawer = ({open}) => {
    const [mainMenu] = useState( MAIN_MENU_LIST );

    return (
        <Drawer variant="permanent"
                open={ open }>
            <Toolbar/>
            <DrawerMenu
                open={open}
                mainMenu={ mainMenu }/>
        </Drawer>
    )
};

export default SideDrawer;
