import { memo, useState } from 'react';
import Grid from "@mui/material/Grid";
import ControlledTextField from "./FormFields/ControlledTextField";
import ControlledDateInput from "./FormFields/ControlledDateInput";
import { useFormContext } from "react-hook-form";
import { defaultCountry, requiredRule } from "./defaults/formsDefaults";
import useData from "../../customHooks/useData";
import AutocompleteField from "./FormFields/AutocompleteField";
import ControlledSuggestionField from "./FormFields/ControlledSuggestionField";
import { useTheme } from "@mui/material/styles";
import { RussianPassportIssuerCodeMask, RussianPassportSeriesNumMask } from "./FormFields/textMasks";
import ControlledSwitchField from "./FormFields/ControlledSwitchField";
import useFieldApiValidation from "../../customHooks/useFieldApiValidation";
import { validateUniqueRussianPassportSeriesNumUri } from "../../utils/uris";
import { isFutureDate } from "../../utils/helperFunctions";

const PassportDataForm = () => {

    const [addressSwitchChecked, setAddressSwitchChecked] = useState( true );
    const [selectedCountry, setSelectedCountry] = useState( defaultCountry.alpha3 );

    const isDefaultCountry = selectedCountry === defaultCountry.alpha3;
    console.log( "is default country", isDefaultCountry )

    const { validateField, isLoading } = useFieldApiValidation( `${ validateUniqueRussianPassportSeriesNumUri }` )

    const theme = useTheme();
    const { setValue, resetField } = useFormContext(); // retrieve all hook methods
    const {
        data: countries
    } = useData( 'api/med/countries/' )

    const handleAddressSwitchChange = (event) => {
        setAddressSwitchChecked( event.target.checked );
        resetField( "living_address" )
        resetField( "living_address_obj" )
        setValue( "living_address_equal_to_registration", event.target.checked )
        setValue( "living_address_obj", {} )
    };


    const handleCountryChange = (countryObj) => {
        const alpha3 = countryObj?.alpha3
        // set value to react-hook-form
        setValue( "citizenship.country", alpha3 )
        // set value to state for conditional fields render
        setSelectedCountry( alpha3 )
        // reset fields
        resetField( "passport.series" )
        resetField( "passport.num" )
        resetField( "passport.fullSeriesNumber" )
        resetField( "passport.issuer_code" )
        resetField( "passport.issued_by" )

        setAddressSwitchChecked( false )
        setValue( "living_address_equal_to_registration", false )
    }

    const handleFmsSuggestion = (suggestion) => {
        // handle Dadata suggestion
        console.log( suggestion )
        const { name, code } = suggestion.data
        setValue( "passport.issued_by", name )
        setValue( "passport.issuer_code", code )
    }


    const passportAddressSuggestionHandler = (suggestion) => {
        console.log( suggestion )
        setValue( "registration_address", suggestion.value )
        setValue( "registration_address_obj", suggestion.data )
    };

    const livingAddressSuggestionHandler = (suggestion) => {
        console.log( suggestion )
        setValue( "living_address", suggestion.value )
        setValue( "living_address_obj", suggestion.data )
    };


    return (
        <Grid container flex flexDirection="column" gap={ theme.spacing( 2 ) }>
            <Grid container flex flexDirection="row" spacing={ theme.spacing( 2 ) }>

                <Grid item xs={ 4 }>
                    <AutocompleteField
                        fieldLabel="Гражданство"
                        options={ countries }
                        preSelectedOption={ defaultCountry }
                        changeHandler={ handleCountryChange }
                        isOptionEqualToValue={
                            (option, value) => option.alpha3 === value.alpha3
                        }
                        getOptionLabel={ (option) => option.name }
                    />
                </Grid>
                {
                    isDefaultCountry ? <Grid item xs={ 4 }>
                        <ControlledTextField
                            rules={ {
                                ...requiredRule,
                                minLength: {
                                    value: 10,
                                    message: "Некорректный ввод"
                                },
                                validate: (value) => validateField( value )
                            } }
                            placeholder="00 00 000000"
                            isLoading={ isLoading }
                            mask={ RussianPassportSeriesNumMask }
                            label="Серия, номер"
                            fieldName="passport.fullSeriesNumber"
                            fullWidth/>
                    </Grid> : <>
                        <Grid item xs={ 4 }>
                            <ControlledTextField
                                fieldName="passport.series"
                                label="Серия"
                                fullWidth/>
                        </Grid>
                        <Grid item xs={ 4 }>
                            <ControlledTextField
                                fieldName="passport.num"
                                label="Номер"
                                rules={ requiredRule }
                                fullWidth/>
                        </Grid>
                    </>
                }

            </Grid>

            { isDefaultCountry && <Grid item>
                <ControlledSuggestionField
                    fieldName="passport.issued_by"
                    label="Быстрый ввод Кем выдан"
                    helperText="Введите Код подразделения (напр. 500161) и выберите орган."
                    rules={ requiredRule }
                    type="fms_unit"
                    suggestionHandler={ handleFmsSuggestion }
                    multiline
                    fullWidth
                    sx={ { height: "auto" } }/>
            </Grid> }
            <Grid container flex flexDirection="row" spacing={ theme.spacing( 2 ) }>
                { isDefaultCountry ? <Grid item xs={ 4 }>
                    <ControlledTextField
                        mask={ RussianPassportIssuerCodeMask }
                        placeholder="000-000"
                        fieldName="passport.issuer_code"
                        label="Код подразделения"
                        rules={ requiredRule }
                        fullWidth/>
                </Grid> : null }


                <Grid item xs={ 4 }>
                    <ControlledDateInput
                        disableFuture
                        fieldName="passport.from_date"
                        label="Дата выдачи"
                        rules={ {
                            ...requiredRule,
                            validate: (value) => !isFutureDate( value ) || "Будущая дата"
                        } }
                        fullWidth/>
                </Grid>
            </Grid>

            <Grid item xs={ 12 }>

                <ControlledSuggestionField fieldName="registration_address"
                                           label={ !isDefaultCountry ? "Адрес временной регистрации" : "Адрес регистрации" }
                                           rules={ {
                                               required: isDefaultCountry ? "Обязательное поле" : false
                                           } }
                                           type="address"
                                           onIdleOutHandler={ (query) => setValue( "registration_address", query ) }
                                           suggestionHandler={ passportAddressSuggestionHandler }
                                           sx={ { height: "auto" } }/>

            </Grid>
            <Grid item xs={ 12 }>

                <ControlledSwitchField fieldName="living_address_equal_to_registration"
                                       changeHandler={ handleAddressSwitchChange }
                                       label={ isDefaultCountry ?
                                           "Адрес проживания совпадает с адресом регистрации" :
                                           "Проживает не в России" }/>


            </Grid>

            { !addressSwitchChecked &&
                <Grid container flex flexDirection="row">
                    <Grid item xs={ 12 }>
                        <ControlledSuggestionField
                            fieldName="living_address"
                            onIdleOutHandler={ (query) => setValue( "living_address", query ) }
                            type="address"
                            label="Адрес проживания"
                            rules={ { ...(isDefaultCountry && requiredRule) } }
                            suggestionHandler={ livingAddressSuggestionHandler }
                            sx={ { height: "auto" } }
                        />
                    </Grid>
                </Grid> }
        </Grid>
    );
};


export default memo( PassportDataForm );