import dayjs from "dayjs";
import React from "react";


/**
 * Parses an object and its nested properties, converting any null, empty strings, or non-object values into an array of error objects.
 * Each error object contains a path to the problematic property and an associated error message.
 * If the input value is not an object, an empty array will be returned.
 *
 * @param {Object} data - The input object to be parsed for errors.
 * @returns {Array} - An array of error objects, each containing the path to the erroneous property and an associated error message.
 */
export function parseErrorsToArray(data) {
    const errors = [];

    /**
     * Recursive function to traverse the object and its nested properties to find errors.
     *
     * @param {Object} obj - The current object being traversed.
     * @param {string} path - The current path to the object being traversed (used for error reporting).
     */
    function traverse(obj, path = '') {
        for (let key in obj) {
            if (obj.hasOwnProperty( key )) {
                const value = obj[key];
                const newPath = path ? `${ path }.${ key }` : key;

                if (value === null || value === '') {
                    errors.push( { [newPath]: 'Пустое поле' } );
                } else if (typeof value === 'object') {
                    traverse( value, newPath );
                } else {
                    errors.push( { [newPath]: value } );
                }
            }
        }
    }

    // Start traversing the input object to collect errors
    traverse( data );
    // Map the collected errors to remove any numeric indexes in the property keys
    return errors.map( (error) => {
        const key = Object.keys( error )[0];
        const value = error[key];
        const newKey = key.replace( /\.\d+$/, '' );
        return { [newKey]: value };
    } );
}


/**
 * Converts Day.js objects, arrays containing Day.js objects, and nested objects containing Day.js objects to their string representations.
 * If the input object is a single Day.js object, it will be converted to a string using the format "YYYY-MM-DD".
 * If the input object is an array, the function will apply itself to each element of the array.
 * If the input object is a nested object, the function will apply itself to each value within the object.
 *
 * @param {any} obj - The input object to be processed. It can be a single Day.js object, an array containing Day.js objects, or a nested object containing Day.js objects.
 * @returns {any} - The processed object with all Day.js objects converted to strings. If the input object is neither a Day.js object, an array, nor an object, the original object will be returned unchanged.
 */
export function convertDayjsToStrings(obj) {
    // If the input object is a Day.js object, convert it to a string using the format "YYYY-MM-DD"
    if (obj instanceof dayjs) {
        return obj.format( "YYYY-MM-DD" );
    }
    // If the input object is an array, apply the function to each element of the array
    if (Array.isArray( obj )) {
        return obj.map( (item) => convertDayjsToStrings( item ) );
    }
    // If the input object is a nested object, apply the function to each value within the object
    if (typeof obj === 'object' && obj !== null) {
        const result = {};
        for (let key in obj) {
            if (obj.hasOwnProperty( key )) {
                result[key] = convertDayjsToStrings( obj[key] );
            }
        }
        return result;
    }
    // If the input object is neither a Day.js object, an array, nor an object, return it unchanged
    return obj;
}

export function preparePatientData(data) {
    // prepare data before submission
    const preparedData = convertDayjsToStrings( data );
    // working with phone number
    const phoneNumber = data?.primary_phone_number?.number
    if (phoneNumber) {
        const areaCode = phoneNumber.slice( 0, 3 )
        preparedData.primary_phone_number.number = phoneNumber.slice( 3 )
        preparedData.primary_phone_number.area_code = areaCode
    }

    // passport series and number
    const fullSeriesNumber = preparedData?.passport?.fullSeriesNumber
    if (fullSeriesNumber) {
        const series = fullSeriesNumber.slice( 0, 4 )
        const number = fullSeriesNumber.slice( 4 )
        preparedData.passport.series = series
        preparedData.passport.num = number
        delete preparedData.passport.fullSeriesNumber
    }
    preparedData.registration_address = prepareDadataAddress(
        preparedData?.registration_address_obj,
        preparedData.registration_address
    )

    if (preparedData?.living_address_equal_to_registration) {
        if (preparedData.citizenship.alpha3 === "rus") {
            preparedData.living_address = preparedData.registration_address
        } else {
            preparedData.living_address = null
        }
    } else {
        preparedData.living_address = prepareDadataAddress(
            preparedData?.living_address_obj,
            preparedData.living_address
        )
    }

    delete preparedData.living_address_obj
    delete preparedData.registration_address_obj
    delete preparedData.living_address_equal_to_registration


    return preparedData
}

export function sleep(ms) {
    return new Promise( resolveFunc => setTimeout( resolveFunc, ms ) );
}

export function calcAge(birthDate) {
    return dayjs().diff( dayjs( birthDate ), "year" )
}

export function isFutureDate(date) {
    return dayjs() - dayjs( date ) < 0
}


export const formatPhoneNumber = (number) => {
    if (!number) {
        return
    }
    const countryCode = number.slice( 0, 1 )
    const areaCode = number.slice( 1, 4 )
    const numberPart1 = number.slice( 4, 7 )
    const numberPart2 = number.slice( 7, 9 )
    const numberPart3 = number.slice( 9 )
    return `+${ countryCode } (${ areaCode }) ${ numberPart1 }-${ numberPart2 }-${ numberPart3 }`
}

export const prepareDadataAddress = (
    dadata = {
        "postal_code": null,
        "country": null,
        "country_iso_code": null,
        "federal_district": null,
        "region_fias_id": null,
        "region_kladr_id": null,
        "region_iso_code": null,
        "region_with_type": null,
        "region_type": null,
        "region_type_full": null,
        "region": null,
        "area_fias_id": null,
        "area_kladr_id": null,
        "area_with_type": null,
        "area_type": null,
        "area_type_full": null,
        "area": null,
        "city_fias_id": null,
        "city_kladr_id": null,
        "city_with_type": null,
        "city_type": null,
        "city_type_full": null,
        "city": null,
        "city_area": null,
        "city_district_fias_id": null,
        "city_district_kladr_id": null,
        "city_district_with_type": null,
        "city_district_type": null,
        "city_district_type_full": null,
        "city_district": null,
        "settlement_fias_id": null,
        "settlement_kladr_id": null,
        "settlement_with_type": null,
        "settlement_type": null,
        "settlement_type_full": null,
        "settlement": null,
        "street_fias_id": null,
        "street_kladr_id": null,
        "street_with_type": null,
        "street_type": null,
        "street_type_full": null,
        "street": null,
        "stead_fias_id": null,
        "stead_cadnum": null,
        "stead_type": null,
        "stead_type_full": null,
        "stead": null,
        "house_fias_id": null,
        "house_kladr_id": null,
        "house_cadnum": null,
        "house_type": null,
        "house_type_full": null,
        "house": null,
        "block_type": null,
        "block_type_full": null,
        "block": null,
        "entrance": null,
        "floor": null,
        "flat_fias_id": null,
        "flat_cadnum": null,
        "flat_type": null,
        "flat_type_full": null,
        "flat": null,
        "flat_area": null,
        "square_meter_price": null,
        "flat_price": null,
        "room_fias_id": null,
        "room_cadnum": null,
        "room_type": null,
        "room_type_full": null,
        "room": null,
        "postal_box": null,
        "fias_id": null,
        "fias_code": null,
        "fias_level": null,
        "fias_actuality_state": null,
        "kladr_id": null,
        "geoname_id": null,
        "capital_marker": null,
        "okato": null,
        "oktmo": null,
        "tax_office": null,
        "tax_office_legal": null,
        "timezone": null,
        "geo_lat": null,
        "geo_lon": null,
        "beltway_hit": null,
        "beltway_distance": null,
        "metro": null,
        "divisions": null,
        "qc_geo": null,
        "qc_complete": null,
        "qc_house": null,
        "history_values": null,
        "unparsed_parts": null,
        "source": null,
        "qc": null
    },
    value = ""
) => {

    let result;

    if (dadata) {
        result = {
            "country": {
                "name": dadata.country,
                "alpha2": dadata.country_iso_code,
            },
            "region": {
                "id": dadata.region_fias_id,
                "name": dadata.region,
                "type": {
                    "name": dadata.region_type_full,
                    "short_name": dadata.region_type
                },
                "iso_code": dadata.region_iso_code
            },
            "area": {
                "id": dadata.area_fias_id,
                "name": dadata.area,
                "type": {
                    "name": dadata.area_type_full,
                    "short_name": dadata.area_type
                }
            },
            "city": {
                "id": dadata.city_fias_id,
                "name": dadata.city,
                "type": {
                    "name": dadata.city_type_full,
                    "short_name": dadata.city_type
                }
            },
            "city_district": {
                "id": dadata.city_district_fias_id,
                "name": dadata.city_district,
                "type": {
                    "name": dadata.city_district_type_full,
                    "short_name": dadata.city_district_type
                }
            },
            "settlement": {
                "id": dadata.settlement_fias_id,
                "name": dadata.settlement,
                "type": {
                    "name": dadata.settlement_type_full,
                    "short_name": dadata.settlement_type
                }
            },
            "street": {
                "id": dadata.street_fias_id,
                "name": dadata.street,
                "type": {
                    "name": dadata.street_type_full,
                    "short_name": dadata.street_type
                }
            },
            "stead": {
                "id": dadata.stead_fias_id,
                "name": dadata.stead,
                "type": {
                    "name": dadata.stead_type_full,
                    "short_name": dadata.stead_type
                }
            },
            "house": {
                "id": dadata.house_fias_id,
                "name": dadata.house,
                "type": {
                    "name": dadata.house_type_full,
                    "short_name": dadata.house_type
                },
                "oktmo": dadata.oktmo,
                "okato": dadata.okato,
                "tax_office": dadata.tax_office,
                "tax_office_legal": dadata.tax_office_legal
            },
            "flat": {
                "id": dadata.flat_fias_id,
                "name": dadata.flat,
                "type": {
                    "name": dadata.flat_type_full,
                    "short_name": dadata.flat_type
                }
            },
            "room": {
                "id": dadata.room_fias_id,
                "name": dadata.room,
                "type": {
                    "name": dadata.room_type_full,
                    "short_name": dadata.room_type
                }
            },
            "value": value,
            "fias_id": dadata.fias_id,
            "geo_lat": dadata.geo_lat,
            "geo_lon": dadata.geo_lon,
            "qc_geo": dadata.qc_geo,
            "block": dadata.block,
            "block_type_full": dadata.block_type_full,
            "postal_code": {
                "name": dadata.postal_code
            }
        }
    } else if (!value) {
        return null
    } else {
        result = {
            "value": value
        }
    }

    for (const [key, value] of Object.entries( result )) {
        if ((typeof value === "object") && (!value?.name)) {
            delete result[key]
        }
    }
    return result
}

export const isDevMode = process.env.NODE_ENV === "development"

export const renderJsonView = (data) => {
    return <pre>{ JSON.stringify( data, null, 2 ) }</pre>
}
