import React from 'react';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import CardBase from "../../Cards/CardElements/CardBase";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { LoadingButton } from "@mui/lab";
import SaveIcon from '@mui/icons-material/Save';

const EditFormDialog = (
    {
        open, onCancel, onSubmit, isSubmitting, title, formComponent, contentText
    }
) => {

    return (
        <Dialog open={ open }
            // onClose={ onClose }
                fullWidth
                maxWidth="sm">
            <DialogTitle>
                { title }
            </DialogTitle>
            <DialogContent>
                <DialogContentText sx={ { pb: 2 } }>
                    { contentText }
                </DialogContentText>
                <CardBase disableElevation>
                    { formComponent }
                </CardBase>

            </DialogContent>

            <DialogActions
                sx={ { pt: 1, pb: 2, pl: 3, pr: 3 } }>
                <Button onClick={ onCancel }>
                    Отмена
                </Button>
                <LoadingButton onClick={ onSubmit }
                               loading={ isSubmitting }
                               endIcon={ <SaveIcon/> }
                               loadingPosition="end">
                    Сохранить
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default EditFormDialog;