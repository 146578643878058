import React from 'react';
import DropdownMenu from "./DropdownMenu";

const DropdownMenuButton = ({ renderButton, menuItems }) => {

    const [anchorEl, setAnchorEl] = React.useState( null );

    const handleClick = (event) => {
        setAnchorEl( event.currentTarget );
    };
    const handleClose = () => {
        setAnchorEl( null );
    };


    return (
        <>
            { renderButton( { onClick: (event) => handleClick( event ) } ) }

            <DropdownMenu anchorEl={ anchorEl }
                          handleClose={ handleClose }
                          isOpen={ !!anchorEl }
                          menuItems={ menuItems }/>
        </>
    );
};

export default DropdownMenuButton;