import PassportCard from "../../Cards/PassportCard";
import React, { memo } from "react";

/**
 * PassportInfo Component
 * Renders a PassportCard component based on the provided citizenships data.
 * @param {Object[]} citizenships - An array containing citizenship information.
 * @param {string} citizenships[].country - The country of the citizenship.
 * @param {Object[]} citizenships[].passports - An array containing passport information.
 * @param {string} citizenships[].passports[].number - The passport number.
 * @returns {JSX.Element} - The PassportCard component with appropriate data or error message.
 */
const PassportInfo = ({ citizenships }) => {
    console.log( "PassportInfo render" );

    let message; // Holds the error message, if any.

    // Check if citizenships data is null or undefined, meaning no data available.
    if (!citizenships) {
        console.log( "PassportInfo no object" );
        message = "Ошибка получения данных"; // Set the error message.
    } else if (citizenships.length > 1) {
        // If the patient has multiple citizenships, display an error message.
        message = "У пациента несколько гражданств"; // Set the error message.
    } else if (citizenships.length === 0) {
        // If the patient has no citizenships, display an error message.
        message = "Гражданство и паспорт не указаны"; // Set the error message.
    } else {
        // If the patient has only one citizenship, check the passport data.
        const { country, passports } = citizenships[0]; // Destructure country and passports from the single citizenship object.

        // Check if passports data is missing for the single citizenship.
        if (!passports) {
            message = `Паспортные данные не указаны`; // Set the error message.
        } else if (passports.length > 1) {
            // If the patient has multiple passports for the single citizenship, display an error message.
            message = `У пациента несколько паспортов гражданства + ${ country }`; // Set the error message.
        } else {
            // If the patient has only one passport for the single citizenship, render the PassportCard with passport data.
            const {
                series,
                num,
            } = passports[0] ?? {}
            if (series || num) {
                return <PassportCard passportObj={ passports[0] } countryObj={ country }/>
            }
            message = "Паспортные данные не указаны"
        }
    }

    // If any of the conditions above matched and an error message is set, render the PassportCard with the error message.
    return <PassportCard errorMessage={ message }/>;
};

export default memo( PassportInfo );