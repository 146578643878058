import React, { useEffect, useState } from 'react';
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";

const Tabs = ({ tabsArr = [[]], initTab }) => {

    const [tabNum, setTabNum] = useState( "" );

    useEffect( () => {
        setTabNum( initTab )
    }, [initTab] )

    const handleTabChange = (event, newValue) => {
        setTabNum( newValue );
    };
    return (
        <>
            {
                !!tabNum && <TabContext value={ tabNum }>
                    <TabList onChange={ handleTabChange } aria-label="patient details tabs">
                        { tabsArr.map( tab => {
                            return <Tab label={ tab[0] } value={ tab[0] } key={ tab[0] }/>
                        } ) }
                    </TabList>
                    { tabsArr.map( tabPanel => {
                        return (
                            <TabPanel value={ tabPanel[0] }
                                      key={ tabPanel[0] }
                                      sx={ { pl: 0, pr: 0 } }>
                                { tabPanel[1] }
                            </TabPanel>
                        )
                    } ) }
                </TabContext>
            }
        </>
    );
};

export default Tabs;