export const requiredRule = { required: "Обязательное поле" };

export const defaultCountry = {
    alpha3: "rus",
    alpha2: "ru",
    name: "Россия",
    full_name: "",
    alpha3_num: 643,
    phone_code: 7
};

export const defaultPersonalData = {
    // birth_date: dayjs(new Date(1970, 0, 1)),
    birth_date: "",
    name: "",
    surname: "",
    patronymic: "",
    gender: "",
}

export const defaultPassportData = {
    from_date: null,
    thru_date: null,
    series: "",
    num: "",
    issued_by: "",
    issuer_code: ""
}