import React from 'react';
import { Stack } from "@mui/material";
import ControlledTextField from "./ControlledTextField";
import { useFormContext } from "react-hook-form";

const PasswordRepeatField = (
    { passwordFieldName = "password", fieldName = "password2", }
) => {
    const { watch } = useFormContext(); // retrieve control hook method
    return (
        <Stack>
            <ControlledTextField
                fieldName={ fieldName }
                rules={ {
                    validate: () =>
                        watch( passwordFieldName ) ===
                        watch( fieldName ) ||
                        "Пароли не совпадают.",
                    required: "Повторите, пожалуйста, пароль."
                } }
                type="password"
                label="Повторите пароль"
            />
        </Stack>
    );
};

export default PasswordRepeatField;