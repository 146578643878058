import { useCallback, useContext, useState } from "react";
import AuthContext from "../store/auth-context";

function useApi(
    endpoint = "",
    method = "POST",
    baseUrl = `${ process.env.REACT_APP_API_URL }`,
) {

    const [data, setData] = useState( null );
    const [error, setError] = useState( null );
    const [isLoading, setIsLoading] = useState( false );

    // Get the authentication context
    const { authHeader } = useContext( AuthContext );

    const makeRequest = useCallback( async (requestData = null) => {

        if (!endpoint) {
            return;
        }

        const fullUrl =  baseUrl + endpoint;

        setIsLoading( true );
        console.log( `useApi is making ${method} request to ${fullUrl}` );

        const response = await fetch( fullUrl, {
            method: method,
            headers: {
                "Content-Type": "application/json",
                ...authHeader,
            },
            ...(requestData) && {
                body: JSON.stringify( requestData )
            },
        } );


        const json = await response.json().catch(error => {});

        console.log( "useApi response json", json )

        setIsLoading( false );

        console.log( "useApi res", response )
        if (!response.ok) {

            const errorObj = {
                response: {
                    ok: response.ok,
                    status: response.status,
                    statusText: response.statusText,
                    url: response.url
                },
                body: json
            }

            console.log( "useApi error", errorObj )
            setError( errorObj )
            throw errorObj;
        }

        setData( json );
        console.log( "useApi res status:", response.status )
        console.log( "useApi res data:", json )

        return json

    }, [baseUrl, endpoint, method, authHeader] )


    return { makeRequest, data, error, isLoading };
}

export default useApi;