import React, { useCallback } from 'react';
import TextField from "@mui/material/TextField";
import { Controller, useFormContext } from "react-hook-form";
import PropTypes from "prop-types";
import CircularProgress from '@mui/material/CircularProgress';
import { InputAdornment } from "@mui/material";
// let renderCount = 0

const ControlledTextField = React.memo(
    ({
         fieldName,
         label,
         rules,
         changeHandler,
         blurHandler,
         mask,
         isLoading,
         readOnly = false,
         ...other
     }) => {

        // renderCount++;
        // console.log( `ControlledTextField.js ${ fieldName } renderCount`, renderCount );

        const { control } = useFormContext(); // retrieve control hook method

        const memoizedChangeHandler = useCallback( (e) => {
            changeHandler && changeHandler( e );
        }, [changeHandler] );

        const memoizedBlurHandler = useCallback( (e) => {
            // setCurrentFocus( null );
            blurHandler && blurHandler( e );
        }, [blurHandler] );


        return (
            <Controller
                name={ fieldName }
                control={ control }
                rules={ rules }
                render={ ({
                              field: { onChange, onBlur, value, name, ref },
                              fieldState: { invalid, error }
                          }) => {
                    return (
                        <TextField label={ label }
                                   error={ invalid }
                                   required={ !readOnly && !!rules?.required }
                                   onFocus={ () => {
                                       // setCurrentFocus( name );
                                   } }
                                   onBlur={ (e) => {
                                       memoizedBlurHandler( e )
                                       onBlur( e )
                                   } }
                                   onChange={ (e) => {
                                       memoizedChangeHandler( e )
                                       onChange( e )
                                   } }
                                   name={ name }
                                   ref={ ref }
                                   value={ value }
                                   autoComplete='new-password'
                                   InputProps={
                                       {
                                           readOnly: readOnly,
                                           ...(mask && { inputComponent: mask }),
                                           ...(isLoading && {
                                               endAdornment: <InputAdornment position="end">
                                                   <CircularProgress/>
                                               </InputAdornment>
                                           })
                                       }
                                   }
                                   helperText={ invalid ? error.message : other.helperText || " " }
                                   { ...other }
                        />
                    );
                } }
            />
        );
    }
);

ControlledTextField.displayName = "ControlledTextField"
ControlledTextField.propTypes = {
    fieldName: PropTypes.string.isRequired,
    rules: PropTypes.object,
    changeHandler: PropTypes.func,
    blurHandler: PropTypes.func,
    // any other props that the component expects, for example:
    label: PropTypes.string.isRequired,
    fullWidth: PropTypes.bool,
};

export default ControlledTextField;
