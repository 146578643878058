import React from 'react';
import CardBase from "../Cards/CardElements/CardBase";
import CardTitle from "../Cards/CardElements/CardTitle";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import Grid from "@mui/material/Grid";
import CardRow from "../Cards/CardElements/CardRow";
import { useTheme } from "@mui/material/styles";

const AccountInfo = ({ phone_number = null, email = null, id = null }) => {
    const { spacing } = useTheme();
    let errorMessage;
    return (
        <CardBase>
            <CardTitle title="Учетные данные"
                       icon={ errorMessage && <WarningRoundedIcon color="warning"/> }/>
            {/*<CardSubtitle subtitle={ errorMessage || `${ series } ${ num }` }/>*/ }

            <Grid container flex flexDirection="column" gap={ spacing( 3 ) }>
                <CardRow title="Номер телефона">{ phone_number || "Не указан" }</CardRow>
                <CardRow title="Email">{ email || "Не указан" }</CardRow>
            </Grid>


        </CardBase>
    );
};

export default AccountInfo;