import React from 'react';
import TextField from "@mui/material/TextField";
import { InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import ClearIcon from '@mui/icons-material/Clear';

const SearchField = ({ searchQuery, queryChangeHandler, clearHandler, ...textFieldProps }) => {

    return (

        <TextField value={ searchQuery }
                   onChange={ queryChangeHandler }
                   {...textFieldProps}
                   placeholder="Поиск..."
                   autoFocus
                   autoComplete='off'
                   variant="standard"
                   // label="Поиск"
                   InputProps={ {
                       startAdornment: (
                           <InputAdornment position="start">
                               <SearchIcon/>
                           </InputAdornment>
                       ),
                       ...((!!clearHandler && !!searchQuery) && {
                           endAdornment: (
                               <InputAdornment position="end">
                                   <IconButton onClick={ clearHandler }>
                                       <ClearIcon/>
                                   </IconButton>
                               </InputAdornment>
                           ),
                       }),
                   } }
        />

    );
};

export default SearchField;