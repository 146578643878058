import React from 'react';
import CardBase from "../../Cards/CardElements/CardBase";
import CardTitle from "../../Cards/CardElements/CardTitle";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import CardSubtitle from "../../Cards/CardElements/CardSubtitle";
import Grid from "@mui/material/Grid";
import CardRow from "../../Cards/CardElements/CardRow";
import { useTheme } from "@mui/material/styles";

const AddressesInfo = ({
                           addresses = {
                               registration_address: null,
                               living_address: null
                           }
                       }) => {
    let errorMessage;

    const { registration_address, living_address } = addresses

    if (!registration_address && !living_address) {
        errorMessage = "Не указаны"
    }
    const { spacing } = useTheme();
    return (
        <CardBase>
            <CardTitle title="Адреса"
                       icon={ errorMessage && <WarningRoundedIcon color="warning"/> }/>
            <CardSubtitle subtitle={ errorMessage || null }/>
            {
                !errorMessage && <Grid container flex flexDirection="column" gap={ spacing( 3 ) }>
                    <CardRow title="Адрес регистрации">{ registration_address || "Не указано" }</CardRow>
                    <CardRow title="Адрес проживания">{ living_address || "Не указано" }</CardRow>
                </Grid>
            }

        </CardBase>
    )
}

export default AddressesInfo;