import React, { useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ListItemIcon from '@mui/material/ListItemIcon';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

const PasswordRequirements = ({ password = "" }) => {

    const [requirements, setRequirements] = useState( {
        length: {
            isValid: false,
            mask: /.{8,20}/,
            text: "от 8 символов"
        },
        upperCase: {
            isValid: false,
            mask: /(?=.*[A-Z])(?=.*[a-z])/,
            text: "одну заглавную букву и одну строчную"
        },
        // lowerCase: {
        //     isValid: false,
        //     mask: /.*[a-z]/,
        //     text: "одну строчную букву"
        // },
        digit: {
            isValid: false,
            mask: /.*[0-9]/,
            text: "одну цифру"
        },
        special: {
            isValid: false,
            mask: /.*[!?@#$%^&*()\-_<>[\]`~+{}.]/,
            text: "специальный символ !?@#$%^&*()\\-_<>[]`~+{}."
        },
        noSpaces: {
            isValid: false,
            mask: /^[A-Za-z0-9.!?@#$%^&*()\-_<>[\]`~+{}]+$/,
            text: "без пробелов"
        }
    } )


    useEffect( () => {
        // check password requirements
        const requirementsKeys = Object.keys( requirements )
        requirementsKeys.forEach( key => {
            setRequirements( prevState =>
                ({
                    ...prevState,
                    [key]: { ...requirements[key], isValid: requirements[key].mask.test( password ) }
                }) );
        } )
    }, [password] );


    return (
        <Grid container flex flexDirection="column">
            <Grid item>
                <Typography variant="caption">Пароль должен содержать:</Typography>
            </Grid>
            <Grid item>
                <List dense>
                    { Object.keys( requirements ).map( key => (
                        <ListItem dense disableGutters key={ key }>
                            <ListItemIcon
                                sx={ { minWidth: "30px" } }
                            >
                                { requirements[key].isValid ?
                                    <CheckCircleRoundedIcon color="success"/> : <CancelRoundedIcon/> }
                            </ListItemIcon>
                            <ListItemText
                                primary={ requirements[key].text }
                                primaryTypographyProps={ { variant: "caption" } }
                            />
                        </ListItem>
                    ) ) }
                </List>
            </Grid>
        </Grid>
    );
};

export default PasswordRequirements;

export const regexMask = /^(?=.*[A-Z])(?=.*[!?@#$%^&*()\-__<>[\]`~+{}.])(?=.*[0-9])(?=.*[a-z])(?=[A-Za-z0-9.!?@#$%^&*()\-__<>[\]`~+{}.]+$).{8,20}$/