import React from 'react';
import CardBase from "../Cards/CardElements/CardBase";
import CardTitle from "../Cards/CardElements/CardTitle";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";

const UserGroupsInfo = ({ groups = [] }) => {
    const { spacing } = useTheme();
    let errorMessage;
    if (!groups || groups.length === 0) {
        return
    }
    return (
        <CardBase>
            <CardTitle title="Группы прав доступа"
                       icon={ errorMessage && <WarningRoundedIcon color="warning"/> }/>
            {/*<CardSubtitle subtitle={ errorMessage || `${ series } ${ num }` }/>*/ }

            <Grid container flex flexDirection="column" gap={ spacing( 3 ) }>
                {
                    groups.map( group => <div key={ group.id }>{ group.name }</div> )
                }
            </Grid>


        </CardBase>
    );
};

export default UserGroupsInfo;