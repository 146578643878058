import React from 'react';
import CardBase from "../../Cards/CardElements/CardBase";
import CardTitle from "../../Cards/CardElements/CardTitle";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import Grid from "@mui/material/Grid";
import CardRow from "../../Cards/CardElements/CardRow";
import { useTheme } from "@mui/material/styles";
import dayjs from "dayjs";
import { calcAge } from "../../../utils/helperFunctions";

const PersonalInfo = ({ surname, name, patronymic, birth_date, gender }) => {
    let errorMessage;
    if (!surname && !name) {
        errorMessage = "Не указаны"
    }
    const { spacing } = useTheme();

    const birthDateToString = (birthDate) => {
        if (!birthDate) {
            return
        }
        return `${ dayjs( birth_date ).format( "DD.MM.YYYY" ) }`
    }

    return (
        <CardBase>
            <CardTitle title="Персональные данные"
                       icon={ errorMessage && <WarningRoundedIcon color="warning"/> }/>
            <Grid container flex flexDirection="column" gap={ spacing( 3 ) }>
                <CardRow title="Дата рождения">{ birthDateToString( birth_date ) }</CardRow>
                <CardRow title="Возраст">{ calcAge( birth_date ) }</CardRow>
                <CardRow title="Пол">{ gender }</CardRow>
            </Grid>


        </CardBase>
    );
};

export default PersonalInfo;