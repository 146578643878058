import { redirect } from "react-router-dom";

export function getAccessToken() {
    return localStorage.getItem( 'accessToken' )
}

export function checkAuthLoader() {
    const token = getAccessToken()
    if (!token) {
        return redirect( "/login" )
    }
    return null
}
