import React, { useMemo, useState } from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ruRU } from '@mui/x-data-grid';
import { ruRU as coreRuRU } from '@mui/material/locale';
import { ruRU as datesRuRU } from '@mui/x-date-pickers/locales';

const ColorModeContext = React.createContext( {
        toggleColorMode: () => {
        },
        mode: "light",
        isDarkMode: false,
        formVariant: 'filled'
    }
)

export function ColorModeContextProvider(props) {

    const initThemeMode = localStorage?.getItem( 'themeMode' ) || "light"

    const [mode, setMode] = useState( initThemeMode );
    const [formVariant] = useState( 'filled' )

    const toggleColorMode = () => {
        setMode( (prevMode) => {
            const newMode = prevMode === 'light' ? 'dark' : 'light'
            localStorage.setItem( 'themeMode', newMode );
            return newMode
        } );
    }


    const
        theme = useMemo(
            () =>
                createTheme( {
                        palette: {
                            mode,
                            ...(mode === "dark" ? {
                                background: {
                                    default: '#242424',
                                    paper: '#292929',
                                },
                                error: {
                                    main: '#ff6363',
                                    light: '#FF8282',
                                    dark: '#B24545',
                                    contrastText: 'rgba(0, 0, 0, 0.87)'
                                }
                            } : {
                                background: {
                                    paper: '#f5f5f5'
                                }
                            })
                        },
                        shape: {
                            borderRadius: 5
                        },
                    components: {
                            MuiTextField: {
                                defaultProps: {
                                    variant: formVariant
                                }
                            },
                        MuiGrid: {
                                defaultProps: {
                                    // spacing: 2
                                }
                        }
                    },
                    },
                    ruRU,
                    coreRuRU,
                    datesRuRU
                ),
            [formVariant, mode]
        );

    const contextValue = {
        mode: mode,
        isDarkMode: mode === "dark",
        toggleColorMode: toggleColorMode,
        formVariant: formVariant
    }

    return (
        <ColorModeContext.Provider value={ contextValue }>
            <ThemeProvider theme={ theme }>
                { props.children }
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
}

export default ColorModeContext;