import React, { useCallback } from 'react';
import { FormControlLabel, Switch } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import PropTypes from "prop-types";

const ControlledSwitchField = React.memo(
    ({
         fieldName,
         rules,
         changeHandler,
         label,
         ...other
     }) => {

        const { control } = useFormContext(); // retrieve control hook method

        const memoizedChangeHandler = useCallback( (e) => {
            changeHandler && changeHandler( e );
        }, [changeHandler] );

        return (
            <Controller
                name={ fieldName }
                control={ control }
                render={ ({
                              field: { onChange, value, name, ref },
                              fieldState: { invalid, isTouched, isDirty, error },
                          }) => {
                    return (
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={ value }
                                    onChange={ (e) => {
                                        memoizedChangeHandler( e )
                                        onChange( e )
                                    } }
                                    name={ name }
                                    inputRef={ ref }
                                    inputProps={ { 'aria-label': 'controlled' } }
                                    { ...other }

                                />
                            }
                            label={ label }
                        />
                    )
                } }
            />
        );
    }
)

ControlledSwitchField.displayName = "ControlledSwitchField"
ControlledSwitchField.propTypes = {
    fieldName: PropTypes.string.isRequired,
    rules: PropTypes.object,
    changeHandler: PropTypes.func,
    label: PropTypes.string.isRequired,
};


export default ControlledSwitchField;