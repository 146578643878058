import React from 'react';
import CardBase from "../../Cards/CardElements/CardBase";
import CardTitle from "../../Cards/CardElements/CardTitle";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import CardSubtitle from "../../Cards/CardElements/CardSubtitle";

const SnilsInfo = ({ snils }) => {
    let errorMessage;
    if (!snils) {
        errorMessage = "Не указан"
    }
    return (
        <CardBase>
            <CardTitle title="СНИЛС"
                       icon={ errorMessage && <WarningRoundedIcon color="warning"/> }/>
            <CardSubtitle subtitle={ errorMessage || snils }/>
        </CardBase>
    )
}

export default SnilsInfo;